import React from 'react'
import { Icon } from '@material-ui/core';
import PropTypes from 'prop-types';

const DeviceTypeFeaturesIcons = (props) => {
    const {hasGps, simSupport, hasEth} = props;
    const result = [];
    
    simSupport && result.push (<Icon color="primary"  title="GSM">sim_card</Icon>);
    hasEth && result.push (<Icon color="primary" title="Puerto ethernet">public</Icon>)
    hasGps && result.push (<Icon color="primary" title="GPS">gps_fixed</Icon>)

    return result;

}

DeviceTypeFeaturesIcons.propTypes = {
    hasGps: PropTypes.bool,
    simSupport: PropTypes.bool,
    hasEth: PropTypes.bool,
}

export default DeviceTypeFeaturesIcons;