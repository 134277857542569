import React, { Component } from 'react'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import loggedUserServerFetch from '../services/loggedUserServerFetch';
import { Box, Paper } from '@material-ui/core';

import Table from "../components/Table";
import PopUp from '../components/popup/Popup.js';


class AbstractAbmPage extends Component {
    constructor (props) {
        super(props);

        this.tableRef = React.createRef();

        const timer = this.setUpdateTimer();

        this.popupMaxWidth = 'md';

        this.state = {
            showForm: false,
            timer: timer,

            item: null,
            isNew: null,
            title: null,
        }        

        /* ******************************************* */
        /* EJEMPLO:
        this.formComponent = CustomerForm;

        this.strings = {
            createItem: 'Nuevo cliente',
            editItem: 'Editar cliente',
            itemCreationSuccess: 'Cliente creado exitosamente',
            itemUpdateSuccess: 'Cliente actualizado exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el cliente seleccionado',
                save: 'Ocurrió un error al guardar el cliente. Intente nuevamente.',
            }
        }

        this.urls = {
            list: API_CUSTOMER_LIST,
            item: API_CUSTOMER,
            toggle: API_CUSTOMER_ACTIVATE,
            delete: API_CUSTOMER_DELETE,
            create: API_CUSTOMER_CREATE,
            update: API_CUSTOMER_EDIT,
        }

        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'description', field: 'description', title: 'Cliente', sorting: true},
                {id: 'active', field: 'active', title: 'Activo', render: (e) => <ActiveIcon active={e.active} activeLabel="Cliente activo" inactiveLabel="Cliente inactivo"/>}
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nuevo cliente',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Desactivar cliente':'Activar cliente',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => {
                    return ({
                        icon: 'edit',
                        tooltip: 'Editar cliente',
                        onClick: () => {this.handleEdit(element.id) },
                    })
                },
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar cliente',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar al cliente ${element.description}?`) }
                }),
            ]
        }
        */
        /* ******************************************* */
    }

    setStateAsync = async (state) => {
        return new Promise((resolve) => this.setState(state, resolve) );
    }

    setUpdateTimer = () => {
        this?.state?.timer && clearInterval(this.state.timer);
        const self = this;
        const timer = setInterval ( () => {
            self.updateTable();
        }, this.props?.updateInterval || 5000);
        
        timer && this.updateTable();
        return timer;
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {        
        if (prevProps?.updateInterval !== this.props.updateInterval) {
            this.state.timer && clearInterval(this.state.timer)
            this.setState({...this.state, timer: this.setUpdateTimer()});
        }
    }

    updateTable = () => {
        this.tableRef?.current && this.tableRef?.current?.onQueryChange();
    }

    componentWillUnmount = () => {
        this.state.timer && clearInterval(this.state.timer);
    }

    fetchElements = (query) => {
        if (! window.navigator.onLine) return new Promise ((resolve,reject)  => reject ('Esperando conexión a internet...'));

        let q = {};
        if (query?.search !== null) q['search'] = query.search;
        if (query?.orderBy !== null && query?.orderBy !== undefined) {
            q['sort_field'] = query.orderBy?.field;
            q['sort_order'] = query.orderDirection;
        }
        q['page'] = query?.page + 1;
        q['page_size'] = query?.pageSize;

        const url = this.urls.list;
        const self = this;
        
        return new Promise ( async (resolve,reject) => {
            loggedUserServerFetch (url, 'get', {}, {}, q).then ( (response) => {                
                const result = {
                    data: response?.data?.items || [],
                    page: Math.min((response?.data?.pagination?.page -1)||0,0),
                    totalCount: response?.data?.pagination?.total_items || 0,
                };
                resolve(result);
    
            }).catch ( (error) => {
                if (!error.response) {
                    console.error('Network error updating grid', error);
                    reject ('Conectando...');

                } else if (window.navigator.onLine) {
                    console.error('Error updating grid', error, window.navigator, error.response);
                    toast.error(self.strings.error.server_comm, 'error_server_comm');
                    reject (error);

                } else {
                    reject ('Esperando conexión a internet...');
                }

                
            })                                
        })
    }

    handleCreate = (item = null) => {
        this.setState( {...this.state, showForm: true, item: item, title: this.strings.createItem, isNew: true} );
    }

    handleEdit = async (id) => {
        const url = this.urls.item.replace('{$id}', id);
        const self = this;

        try {
            const response = await loggedUserServerFetch (url, 'get', {}, {}, {});
            const item = response.data;
            this.setState( {...this.state, showForm: true, item: item, title: this.strings.editItem, isNew: false} );

        } catch (error) {
            console.error(error);
            toast.error(self.strings.error.server_comm, 'error_server_comm');            
        }
    }

    handleToggleActive = async (id, active) => {
        const url = this.urls.toggle.replace('{$id}', id);

        try {
            await loggedUserServerFetch (url, 'patch', {active: active}, {}, {});
            this.updateTable();

        } catch (error) {
            console.error(error);
            toast.error(this.strings.error.server_comm, 'error_server_comm');            
        }
    }

    handleDelete = async (id, caption = null) => {
        try {
            const result = await Swal.fire({
                text: caption??'¿Desea eliminar el elemento seleccionado?',
                showCancelButton: true,
                confirmButtonText: 'Eliminar',
                cancelButtonText:'Cancelar',
                icon: 'question',
            });
            
            if (! result.isConfirmed) return;

            const url = this.urls.delete.replace('{$id}', id);
            await loggedUserServerFetch ( url, 'delete');
            this.updateTable();

        } catch (error) {
            console.error(error);
            toast.error(this.strings.error.server_comm, 'error_server_comm');            
        }
    }

    handleClose = () => {
        this.setState( {...this.setState, showForm: false, item: null});
    }

    handleCreateItem = async ( form ) => {
        return await this.handleSaveItem(form, this.urls.create, 'put', this.strings.itemCreationSuccess);
    }

    handleUpdateItem = async ( form, id ) => {
        return await this.handleSaveItem(form, this.urls.update.replace('{$id}', id), 'post', this.strings.itemUpdateSuccess);
    }

    handleSaveItem  = async ( form, url, method, success_string) => {
        let result = null;

        try {
            result = await loggedUserServerFetch(url, method, form);
            result = result?.data;
            this.setState( {...this.state, showForm: false, item: null, isNew: null}, () => this.updateTable());
            toast.success(this.buildMessage(success_string, result), 'success_save_item');

        } catch (error) {
            let msg = this.pickErrorMessage(error?.response?.data?.status || null, this.strins.error.save, error);
            //if (typeof msg === 'function') msg = msg(error);
            console.error(error, error.response);
            toast.error(msg, 'handle_save_error');
        }

        return result;
    }

    pickErrorMessage = ( code, defaultMessage, error = null ) => {
        let msg = defaultMessage;

        if (code) msg = this.strings.error[code] || null;
        if (! msg) msg = defaultMessage;
        
        if (error !== null ) msg = this.buildMessage(msg, error);

        return msg;
    }

    buildMessage = (message, content = null) => {
        return (typeof message === 'function') ? message(content) : message;
    }

    renderForm = () => {
        const Component = this.formComponent;
        return (<Component 
            onClose = { this.handleClose }
            onCreate = { this.handleCreateItem }
            onUpdate = { this.handleUpdateItem }
            isNew = { this.state.isNew }
            values={this.state.item}
        />)
    }

    render = () => {
        const { classes } = this.props;
        return (
            <>
                {this.preRender && (typeof this.preRender === 'function') && this.preRender()}
                <div className={classes.root}>
                    <Box>
                        <Paper>
                            <PopUp
                                title={this.state.title}
                                open={this.state.showForm}
                                classes={classes}
                                onClose = { () => {} }
                                maxWidth={this.popupMaxWidth}
                                fullWidth
                            >
                                {this.renderForm()}
                            </PopUp>
                            <Table
                                columns={this.tableSetup.columns}
                                defaultSortColumnIndex={this.tableSetup.defaultSortColumnIndex}
                                defaultSortDirection={this.tableSetup.defaultSortDirection}
                                data={ query => this.fetchElements(query)}
                                tableRef={this.tableRef}
                                actions={this.tableSetup.actions}
                            />
                        </Paper>
                    </Box>
                </div>
                {this.postRender && (typeof this.postRender === 'function') && this.postRender()}
            </>
        )
    }
}

export default AbstractAbmPage;