import { API_PROFILE_UPDATE_POSITION } from "../constants/api";

export const updatePosition = (position) => {
    const token = localStorage.token;

    const data = new FormData();
    data.append ('lat', position.latitude);
    data.append ('lng', position.longitude);

    return fetch (
        API_PROFILE_UPDATE_POSITION,
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'x-auth': `${token}`,
            },
            body: data,

        }).then( (response) => {
            if(response.ok) {
                return response.json();

            } else {
                throw response.text();
            }
        }
    ) ;
}
