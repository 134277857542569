import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import { API_FIRMWARE_FORM } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import { Grid } from '@material-ui/core';

class FirmwareForm extends Component {
    constructor (props) {
        super(props);
        
        this.state = {
            deviceSetupTemplate: null,
            deviceType: null,

            version: null,
            timestamp: null,
            crc32: null,
            type: null,
        };
        
        this.populateForm();
    }

    populateForm = () => {
        loggedUserServerFetch (API_FIRMWARE_FORM, 'get', {}, {}, {}).then ( (response) => {
            const setupTemplate = response?.data?.device_setup_template || [];
            const deviceType = response?.data?.device_type || [];

            this.setState({...this.state, deviceSetupTemplate: setupTemplate, deviceType: deviceType});

        }).catch ( (error) => {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario de firmwares');
            this.props.onClose();
        })

    }

    handleOnChangeFile = (name, file, content) => {
        let type, major, minor, timestamp, version, crc32 = null;
        //version lo uso para skipear el elemento
        [type, major, minor, timestamp, version, crc32] = file.name.split('.').shift().split('_');        
        version = `${major}.${minor}`;

        if (! type || ! version || ! timestamp || ! crc32 ) {
            toast.error('No se pudo determinar los datos del firmware a partir del nombre del archivo', 'err_parse_filename');
            type = version = crc32 = timestamp = null;
        }

        this.setState({...this.state, version, timestamp, crc32, type} );
    }

    buildSchema = (values) => {
        const deviceTypesOptions = this.state.deviceType.map( e => ({label: e.description, value: e.id}))
        
        let setupFormOptions = {}
        this.state.deviceSetupTemplate && this.state.deviceSetupTemplate?.forEach ( d => setupFormOptions[d.id] = d.description);

        let schema = {fields: [            
            {
                key: 'description',
                name: 'description',
                type: 'textarea',
                label: 'Descripción',
                required: true,
                disabled: false,
                autoFocus: true,
            },
            {
                key: 'active',
                name: 'active',
                type: 'switch',
                label: 'El firmware está disponible para utilizar',
                required: true,
                disabled: false,
            },
            {
                key: 'content',
                name: 'content',
                type: 'dropzone',
                label: 'Archivo',
                required: true,
                disabled: false,
                //acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']},
                dropzoneText: "Arrastrá y soltá un archivo binario o hacé click acá",
                filesLimit: 1,

                showFileNames: true,
                showAlerts: ['error', 'info'],
                alertSnackbarProps: {
                    anchorOrigin: { horizontal: 'right', vertical: 'top'},
                    autoHideDuration: 3000,
                },

                notifyOnChange: this.handleOnChangeFile,
            },
            {
                key: 'deviceType',
                name: 'deviceType',
                type: 'multiselect-autocomplete',
                label: 'Tipo de dispositivo',
                required: true,
                disabled: false,
                options: deviceTypesOptions,
            },
            {
                key: 'setupTemplate',
                name: 'setupTemplate',
                type: 'select',
                label: 'Formulario de configuración',
                required: true,
                disabled: false,
                options: setupFormOptions,
            }
        ]};
        return schema;
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        const schema = this.buildSchema;
        if (this.state.deviceSetupTemplate === null && this.state.deviceType === null) return this.renderLoading();
        return (
            <AbstractForm 
                schema={schema} 
                {...this.props}
            >
                {this.state.version &&<>
                    <br />
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                        <Grid item xs={12} sm={3}>
                            Tipo <strong>{this.state.type}</strong>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            Versión <strong>{this.state.version}</strong>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            Timestamp <strong>{this.state.timestamp}</strong>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            CRC32 <strong>{this.state.crc32}</strong>
                        </Grid>
                    </Grid>
                </>}
            </AbstractForm>)
    }
}

export default FirmwareForm;