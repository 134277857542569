import React, { Component } from 'react';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import { API_DEVICE_DELIVERY_FORM } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import { Grid } from '@material-ui/core';
import { FEATURE_GPS } from '../../constants/device_features';

class DeviceDeliverForm extends Component {
    constructor (props) {
        super(props);
        
        this.state = {
            customers: [],
            location: null,
        };
    }

    componentDidMount = async () => {
        try {
            //Obtener del localStorage
            const lastLocation = {lat: null, lng : null};

            const response = await loggedUserServerFetch (API_DEVICE_DELIVERY_FORM, 'get', {}, {}, {});
            const { customers, defaultLocation} = response?.data;
            const location = (lastLocation && lastLocation.lat) ? lastLocation : defaultLocation;

            await new Promise ( (resolve) => this.setState({...this.state, customers, location, resolve}));

        } catch (error) {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario de comandos');
            this.props.onClose();            
        }
    }

    buildSchema = (values) => {
        const device = this.props.device;

        let customerOptions = {};
        (this.state.customers.length > 0) && this.state.customers.forEach ( d => customerOptions[d.id] = `${d.description}`);
        
        let schema = {fields: [
            {
                key: 'customerId',
                name: 'customerId',
                label: 'Cliente',
                required: true,
                disabled: false,
                autoFocus: true,
                type: 'select',
                options: customerOptions,
            },
        ]};

        if ( (device?.device_type?.features || []).includes(FEATURE_GPS) ) {
            schema.fields.push ({
                key: 'gpsLabel',
                name: 'gpsLabel',
                label: 'La posición se determinará automáticamente desde el GPS del equipo',
                required: false,
                type: 'label',
                style: {paddingTop: '30px'}
            })
        } else {
            schema.fields.push ({
                key: 'position',
                name: 'position',
                label: 'Ubicación del equipo',
                required: true,
                type: 'address_map',
            });
        }        

        return schema;
    }

    validateForm = (form) => {
        /*
        const macRx = new RegExp(/^[a-f0-9]{12}$/);
        const allMacs = form?.macs?.length|| -1;

        if (allMacs < 1) throw Error('Debe ingresar al menos una MAC');

        const validMacs = (form?.macs || []).filter ( mac => mac.match(macRx)).length;

        if (allMacs !== validMacs) throw Error('Hay direcciones MAC con formato incorrecto');
        */
        return true;
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        const schema = this.buildSchema();
        if (this.state.customers.length === 0 ) return this.renderLoading();
        return <AbstractForm 
            schema={schema}
            validateForm={this.validateForm} 
            {...this.props}
            isNew={true}
        />
    }
}

export default DeviceDeliverForm;