import React, { useState } from 'react'
import { Box, Button, DialogContent, Grid } from '@material-ui/core';
import PopUp from '../../popup/Popup';
import { ArrowBack, ArrowForward, Close, Save } from '@material-ui/icons';
import PropTypes from 'prop-types';

import IconPicker from './IconPicker';
import MarkerPreview from './MarkerPreview';
import ColorEditor from './ColorEditor';

const renderButtons = (props) => {

    const buttons = [];
    const cancelButton = 
        <Button 
            type="button"
            variant="contained" 
            color="default"
            title="Cerrar"
            onClick={ props.handleCancel }
            startIcon={<Close />}
        >Cancelar</Button>

    const nextButton = 
        <Button 
            type="button"
            variant="contained" 
            color="default"
            title="Siguiente"
            onClick={ () => props.setPage ( props.currentPage + 1) }
            startIcon={<ArrowForward />}
            disabled={ props.disableNextButton }
        >Siguiente</Button>

    const prevButton = 
        <Button 
            type="button"
            variant="contained" 
            color="default"
            title="Anterior"
            onClick={ () => props.setPage ( props.currentPage - 1) }
            startIcon={<ArrowBack />}
        >Anterior</Button>
    
    const saveButton = 
        <Button
            type="button"
            variant="contained"
            color="primary"
            title="Finalizar"
            startIcon={<Save />}
            disabled={props.disableSaveButton}
            onClick = { props.handleSubmit }
        >Finalizar</Button>
    
    if (props.currentPage === 0) {
        //Primera página: Cancelar, Siguiente
        buttons.push(cancelButton, nextButton);

    } else if (props.currentPage < props.maxPage -1 ) {
        //Páginas del medio: Cancelar, Atrás, Siguiente,
        buttons.push(cancelButton, prevButton, nextButton);

    } else {
        //Última página: Cancelar, Finalizar
        buttons.push(cancelButton, prevButton, saveButton);
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
        >
            <Grid item>
            <Box pt={3} pb={1}>
                {buttons}                
            </Box>
        </Grid>
    </Grid>
    )
}

const DialogIconPicker = (props) => {

    const {icons, categories, baseUrl, onCancel, onSubmit, closeOnSubmit} = props;
    const [page, setPage] = useState(0);
    const [selectedIcon, setSelectedIcon] = useState (null);
    const [selectedColor, setSelectedColor] = useState (null);
    const [selectedColoredIcon, setSelectedColoredIcon] = useState (null);

    const pageContent = [];

    switch (page) {
        case 1:
            pageContent.push (
                <ColorEditor
                    markerUrl = {selectedIcon.url}
                    background = { props.backgroundUrl }
                    onUpdate = { (color, encodedImage) => {
                        setSelectedColor (color);
                        setSelectedColoredIcon (encodedImage);
                    }}
                />
            )
            break;
    
        default:
            pageContent.push(
                <IconPicker 
                    category = { selectedIcon?.category || null }
                    categories = { categories }
                    icons = { icons }
                    baseUrl = { baseUrl }
                    onSelect = { (category, file, url) => setSelectedIcon({category, file, url}) }
                />
            );


            if (selectedIcon?.url) pageContent.push (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid>
                        <MarkerPreview 
                            backgroundUrl = { props.backgroundUrl }
                            markerUrl = { selectedIcon.url }
                        />
                    </Grid>
                </Grid>
                )

            break;
    }

    //currentPage, maxPage, setPage, disableSaveButton, handleSubmit
    pageContent.push ( renderButtons ( {
        currentPage: page, 
        maxPage: 2, 
        setPage, 
        disableSaveButton: selectedColoredIcon === null,
        handleCancel: onCancel,
        handleSubmit: () => {
            onSubmit (selectedColor, selectedColoredIcon);
            closeOnSubmit && onCancel();
        },
        disableNextButton: (page === 0 && selectedIcon === null),
    }));

    return (
        <PopUp
            title="Seleccionar icono"
            open={icons && categories}
            onClose = { () => {} }
            fullWidth
            scroll='paper'
        >
            <DialogContent>{pageContent}</DialogContent>
        </PopUp>
    )
}

DialogIconPicker.propTypes = {
    categories: PropTypes.array.isRequired,
    icons: PropTypes.array.isRequired,
    baseUrl: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    backgroundUrl: PropTypes.string.isRequired,
    
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    closeOnSubmit: PropTypes.func.isRequired,
}

export default DialogIconPicker;