import React from 'react';
import PrivateRoute from './PrivateRoute';
import { PropTypes } from 'prop-types';

const ProtectedRoute = ( { authorizedProfiles, userProfiles, ...rest}) => {
    const isAuthorized = authorizedProfiles.some((val) => userProfiles.indexOf(val) !== -1);
    rest.isAuthenticated = rest?.isAuthenticated && isAuthorized;
    return (<PrivateRoute {...rest}></PrivateRoute>)
}

ProtectedRoute.propTypes = {
    authorizedProfiles: PropTypes.array.isRequired,
    userProfiles: PropTypes.array.isRequired,
}

export default ProtectedRoute;