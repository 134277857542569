import React from 'react'
import { FormControlLabel, FormLabel,  FormControl, RadioGroup, Radio as RadioControl, Box } from '@material-ui/core';

const Radio = ({onChange, value, ...props}) => {
    return (
        <Box pt={3}>
            <FormControl component="fieldset">
                <FormLabel component="legend">{props.label||''}</FormLabel>
                <RadioGroup aria-label="gender" name={props.name} value={value} onChange={(ev) => onChange(ev.target.name, ev.target.value)}>
                    { Object.entries(props.options||{}).map( (item) => (<FormControlLabel key={`field_inner_${props.name}_${item[0]}`} value={ item[0] } control={<RadioControl />} label={ item[1] } /> ))}
                </RadioGroup>
            </FormControl>
        </Box>
    )
}

export default Radio;