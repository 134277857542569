/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    //color: theme.palette.primary.main,
    color: '#4b9451',
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      //color: theme.palette.primary.main
      color: '#4b9451',
    }
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();
  let k = 0;
  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map(page => {
        let result = null;
        switch (page.type||null) {
          
          case 'separator':
            result = <hr key={`separator${k}`} />
            break;
        
          case 'title':
            result = <ListItem key={`title${k}`}><h5>{page.title}</h5></ListItem>
            break;

            default:
            result =           <ListItem
            className={classes.item}
            disableGutters
            key={`${page.title||''}${k}`}
          >        
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href||'#'}
            >
              <div className={classes.icon}>{page.icon||null}</div>
              {page.title}
            </Button>
          </ListItem>

            break;
        };
        k++;
        return(result)
      })}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
