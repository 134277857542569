import React from 'react'
import EnhancedMenu from "../EnhancedMenu";
import { USER_IS_ADMIN, USER_IS_ROOT, USER_IS_CUSTOMER_ADMIN, USER_IS_CUSTOMER } from "../../constants/profiles";

import { store } from "../../redux/store";
import { getUserPermission } from "../../redux/reducers/users";
import { FEATURE_SIM } from '../../constants/device_features';

const buildMenu = (element, props) => {

    const deviceMenu = [];

    //Acá vienen las entradas de menú que se ven siempre (Solo afectadas por permisos de usuario)


    if (element.device_type === null)  {
        deviceMenu.push(
            {            
                caption: 'Establecer tipo de equipo',
                onClick: (elem, event) => props.handleSetDeviceType (elem),
                profiles: [USER_IS_ROOT, USER_IS_ADMIN],
            }
        );

    } else {
        //Actualizar firmware
        deviceMenu.push({
            caption: 'Actualizar firmware',
            disabled: (props.firmwares.length === 0) || (element.ota !== null),
            //style,
            profiles: [USER_IS_ROOT, USER_IS_ADMIN],
            submenu: props.firmwares
                .filter( e => (e?.device_types_id || []).includes(element?.device_type?.id||null))
                .map( e=> ({
                    caption: `Versión ${e.version} - ${e.description}`,
                    onClick: (elem, event) => props.handleDispatchFirmwareUpdate (elem, e),
                }))    
        });
        
        //Poner SIM
        if ( (element?.device_type?.features || []).includes(FEATURE_SIM) && ( element?.simcard === null) ) {
            deviceMenu.push ({
                caption: props.simcards.length === 0 ? 'Asignar tarjeta SIM (Sin stock)': 'Asignar tarjeta SIM',
                disabled: props.simcards.length === 0,
                onClick: (elem, event) => props.handleSetSim (elem),
                profiles: [USER_IS_ROOT, USER_IS_ADMIN],
            });
        }

        //Comandos
        deviceMenu.push({
            caption: 'Comandos',
            disabled: (props.commands.length === 0) || (element.enqueued_command !== null),
            //style,
            profiles: [USER_IS_ROOT, USER_IS_ADMIN, USER_IS_CUSTOMER_ADMIN],
            submenu: props.commands
                .filter( e => e?.device_type?.id === element?.device_type?.id)
                .map( e=> ({
                    caption: e.description,
                    onClick: (elem, event) => props.handleDispatchCommand (elem, e),
                }))    
        });

        if (! element?.customer) deviceMenu.push ( {
            caption: 'Entregar a cliente',
            onClick: (elem) => props.handleDeliverToCustomer (elem),
            profiles: [USER_IS_ROOT, USER_IS_ADMIN],
        })

        if (element?.customer ) deviceMenu.push ( {
            caption: 'Recibir desde cliente',
            onClick: (elem) => props.handleReceiveFromCustomer (elem),
            profiles: [USER_IS_ROOT, USER_IS_ADMIN],
        })

        //Retirar SIM
        if ( (element?.device_type?.features || []).includes(FEATURE_SIM) && ( element?.simcard !== null) ) { 
            deviceMenu.push ({
                caption: 'Retirar tarjeta SIM',
                onClick: (elem, event) => props.handleRemoveSim (elem),
                profiles: [USER_IS_ROOT, USER_IS_ADMIN],
            });
        }
    }

    return deviceMenu;
}

const assembleMenu = (element, onClose, props) => {
    const result = [];
    if ( ! element) return result;

    const deviceMenu = buildMenu(element, props);

    for (let i = 0; i < deviceMenu.length ; i++) {        
        const menuEntry = deviceMenu[i];
        if ( (!! menuEntry.profiles) && (! menuEntry.profiles.includes (props.permission)) ) continue;
        let entry = {};

        entry = { 
            key: `dropdownMenuEntry_${i}`,
            caption: menuEntry.caption,
            disabled: menuEntry.disabled,
            style: menuEntry.style,
        };

        if (menuEntry.submenu) {                     
            entry.subMenuItems = menuEntry.submenu.map( (sub, k) => {
                return (
                    { 
                        key: `dropdownMenuEntry_${i}_${k}`,
                        caption: sub.caption,
                        disabled: sub.disabled,
                        style: sub.style,
                        onClick: (event) => {
                            sub.onClick && sub.onClick(element, event);
                            onClose();
                        }
                    }
                )
            })
       
            
        } else {
            entry.onClick = (event) => {
                menuEntry.onClick && menuEntry.onClick(element, event);
                onClose();
            };

        }

        result.push(entry);
    }
    return result;
}


const DeviceTableDropdownMenu = ({open, onClose, element, anchor, ...props}) => {    
    if (! element) return null;
    const state = store?.getState();
    let tmp = getUserPermission(state);
    const permission = Array.isArray(tmp)? [...tmp].shift() : tmp;

    return (
        <EnhancedMenu
            open={open}
            menuItems={assembleMenu(element,onClose, {...props, permission})}
            anchorElement={anchor}
            onClose={onClose}
      />
    );    
}

export default DeviceTableDropdownMenu;