import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { None } from '../../layouts/';

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  redirect: pathname,
  layout: Layout,
  ...rest
}) => {
  if (!Layout) Layout=None;
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true ? (
          <Layout>
            <Component {...rest} {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.defaultProps = { redirect: '/login' }

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.any.isRequired,
  redirect: PropTypes.string,
}

export default PrivateRoute