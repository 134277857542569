const storageEnabled = typeof(Storage) !== "undefined";

const getItem = (name, defaultValue) => {
    if (! storageEnabled) return defaultValue;
    let value = localStorage.getItem(name);
    
    if (value === null) {
        value = defaultValue;
        setItem (name, value);

    } else {
        var tmp = null;

        try {
            tmp = JSON.parse(value);
            
        } catch (e) {
            tmp = value;
        }
        value = tmp;
    }
    return value;
}

const setItem = (name, value) => {
    if (storageEnabled) localStorage.setItem(name, JSON.stringify(value));    
}

const helper = {
    getItem: getItem,
    setItem: setItem,
};

export default helper;