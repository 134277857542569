import React from 'react'
import dayjs from 'dayjs';
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';
import { Icon } from '@material-ui/core';
import AbstractAbmPage from './AbstractAbmPage.js';
import { API_DEVICE_COMMAND, API_DEVICE_COMMAND_ACTIVATE, API_DEVICE_COMMAND_DELETE, API_DEVICE_COMMAND_LIST } from '../constants/api.js';
import CommandDeviceStatus from '../components/icondisplay/CommandDeviceStatus';

class DeviceCommandPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = null;
        this.strings = {
            createItem: '',
            editItem: '',
            itemCreationSuccess: '',
            itemUpdateSuccess: '',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el comando seleccionado',
                save: '',
            }
        }
        
        this.urls = {
            list: API_DEVICE_COMMAND_LIST,
            item: API_DEVICE_COMMAND,
            toggle: API_DEVICE_COMMAND_ACTIVATE,
            delete: API_DEVICE_COMMAND_DELETE,
            create: null,
            update: null,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 2,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'mac', field: 'device.mac', title: 'Mac', sorting: true},
                {id: 'command', field: 'command.description', title: 'Comando', sorting: true},
                {id: 'created_at', field: 'created_at', title: 'Fecha de creación', sorting: true, render: e =>  (e.created_at && dayjs(e.created_at).format ( 'DD/MM/YYYY HH:mm')) },
                {id: 'downloaded_at', field: 'downloaded_at', title: 'Fecha de descarga', sorting: true, render: e => ( e.downloaded_at && dayjs(e.downloaded_at).format ( 'DD/MM/YYYY HH:mm'))},
                {id: 'executed_at', field: 'executed_at', title: 'Fecha de ejecución', sorting: true, render: e => ( e.executed_at && dayjs(e.executed_at).format ( 'DD/MM/YYYY HH:mm'))},
                {id: 'cancelled_at', field: 'cancelled_at', title: 'Fecha de cancelación', sorting: true, render: e => ( e.cancelled_at && dayjs(e.cancelled_at).format ( 'DD/MM/YYYY HH:mm'))},
                {id: 'status', field: 'status', title: 'Estado', render: (e) => <CommandDeviceStatus command_device={e} />}
            ],
            actions:[
                (element) => ({
                    icon: 'download',
                    tooltip: element.has_param?'Descargar parámetros':'El comando no tiene parámetros',
                    disabled: ! element.has_param,
                    onClick: (event, element) => { this.handleDownloadParameter(element) }
                }),
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Pausar comando':'Activar comando',
                    disabled: element.cancelled || element.executed || element.uploaded,
                    onClick: (event, element) => { this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: element.cancelled?'El comando ya fue cancelado':'Cancelar comando',
                    disabled: element.cancelled || element.executed,
                    onClick: () => { this.handleDelete(element.id, `¿Desea cancelar el comando ${element.command.description} del equipo ${element.device.mac}? `) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(DeviceCommandPage));


