export const FOCUS_REFRESH_TIMEOUT = 5000;
export const NO_FOCUS_REFRESH_TIMEOUT = 30000;
export const RECAPTCHA_KEY = '6LcAdgcUAAAAANhPbwfXM1EzSBgWQTReji-46Daj';
export const GMAPS_KEY = 'AIzaSyAh1M9IjW73IJJ0eRPUsohtJBVOAOrcCSw';
export const MIN_PASSWORD_LENGTH = 8;
export const ITEMS_PER_PAGE = 10;

export const PASSWORD_CHANGE_OK = 0;
export const PASSWORD_MISSING_FIELDS = 1;
export const PASSWORD_TOO_SHORT = 2;
export const PASSWORD_MISMATCH = 3;

export const DEFAULT_RTU_MAP_CENTER = {lat: -34.6084198, lng: -58.371328};
export const DEFAULT_RTU_MAP_ZOOM = 14;

export const MAX_IMAGE_FILE_SIZE = 10000000; //10MB

export const GREENLABS_GREEN = '#4b9451';

export const MAX_GPS_AGING = 500;