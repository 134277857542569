import palette from '../palette';

export default {
  root: {
    color: palette.icon,
    '&:hover': {
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.03)'
    }
  }
};
