import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Box, FormGroup, Grid, InputLabel, TextField } from '@material-ui/core';
import { GoogleMap, LoadScript, Marker  } from '@react-google-maps/api';
import Geocode from "react-geocode";

import {GMAPS_KEY} from '../../../constants/ui'
import MAPSTYLE from '../../../styles/mapStyle';
import browserStorage from '../../../helpers/browserStorage';
import { toast } from 'react-toastify';

const containerStyle = {
    width: '720px',
    height: '300px'
};

Geocode.setApiKey(GMAPS_KEY);
Geocode.setLanguage('es');
Geocode.setRegion('ar');
Geocode.setLocationType("ROOFTOP");

const DelayChangeTextField = (props) => {
    const {onChange, delay = 500, ...myprops} = props;
    const [query, setQuery] = useState(null);

    useEffect(() => {
        const timeOutId = setTimeout(() => onChange(query), delay);
        return () => clearTimeout(timeOutId);
    }, [query])

    return (<TextField fullWidth  {...myprops} onChange={event => setQuery(event.target.value)}/>);
}


class AddressMap extends React.Component {
    constructor (props) {
        super (props);
    
        this.name = `map_control_${props.name}`;

        const buenosAires = {lat: -34.6084198, lng: -58.371328};

        let position = browserStorage.getItem (`${this.name}_center`, props.position || buenosAires);
        const zoom = browserStorage.getItem (`${this.name}_zoom`, props.zoom || 14);

        if ( isNaN (position?.lat)) position.lat = isNaN(props.position.lat) ? buenosAires.lat : props.position.lat;
        if ( isNaN (position?.lng)) position.lng = isNaN(props.position.lng) ? buenosAires.lng : props.position.lng;
        
        this.state = {
            map: null,
            position: position,
            center: position,
            zoom: zoom,
        }
    }

    onLoadMap = (map) => {
        this.setState ({...this.state, map});
    }

    onUnloadMap = (map) => {    
        this.setState ({...this.state, map: null});
    }

    onDragEnd = (event) => {                
        const position = {lat: event.latLng.lat(), lng: event.latLng.lng()} ;
        this.props.onChange (this.props.name, position);
        this.setState ( {...this.state, position });
    }

    onBoundsChanged = () => {
        const zoom = this.state.map.getZoom();
        const center = this.state.map.getCenter();
        const position = {lat: center.lat(), lng: center.lng()};
        browserStorage.setItem (`${this.name}_center`, position);
        browserStorage.setItem (`${this.name}_zoom`, zoom);        
    }

    searchForAddress = async (addr = null) => {        
        try {
            if (! addr) return;
            const response = await Geocode.fromAddress(addr);
            if ((! response.status === 'OK')  || (response.results.length === 0)) throw Error (`No se pudo obtenr las coordenadas para ${addr} `);

            const position = {...response.results[0].geometry.location};
            browserStorage.setItem (`${this.name}_center`, position);
            this.setState ({...this.state, position: position, center: position, zoom: 17});

        } catch (error) {
            toast.error ('No se pudo obtener las coordenadas de la dirección');
        }
    }

    render = () => {
        const {center, position, zoom} = this.state;
    
        return (
        <Box pt={3}>
            <FormGroup row>
                <InputLabel style={{display: 'row'}}>{this.props.label}</InputLabel>
                <DelayChangeTextField defaultValue="" label="Buscar dirección" fullWidth onChange={(value) => this.searchForAddress(value)}/>
                <br />
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    style={{paddingTop: '10px'}}
                >
                    <Grid item>

                        <LoadScript googleMapsApiKey={GMAPS_KEY}>
                            <GoogleMap
                                key={`${this.name}_map_component`}
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={zoom}
                                onLoad={this.onLoadMap}
                                onUnmount={this.onUnloadMap}
                                onBoundsChanged={this.onBoundsChanged}
                                fullWidth
                                options={{
                                    mapTypeControl: false,
                                    styles: MAPSTYLE,
                                }}
                            >
                                <Marker
                                    position={position}
                                    onDragEnd={ this.onDragEnd }
                                    draggable
                                />
                            </GoogleMap>
                        </LoadScript>

                    </Grid>

                </Grid>

            </FormGroup>
        </Box>
        );
    }
}

AddressMap.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
}

export default AddressMap;