import React from 'react'
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const MarkerPreview = (props) => {
    const {backgroundUrl, markerUrl} = props;

    return (                    
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <div 
                    class="text-center" 
                    style={ {backgroundImage: `url('${backgroundUrl}')`, width: '320px', height: '120px', backgroundRepeat:'no-repeat', backgroundSize: 'cover'}}>
                        <img 
                            src={ markerUrl } 
                            id="icon-Preview" 
                            style={{position: 'relative', top: '50px', left: '50%'}} 
                            alt="Marker preview"
                        />
                </div>                

            </Grid>
        </Grid>
    )
}

MarkerPreview.propTypes = {
    backgroundUrl: PropTypes.string.isRequired,
    markerUrl: PropTypes.string.isRequired,    
}

export default MarkerPreview