import React, { Component } from 'react'
import BackgroundImage from '../components/BackgroundImage';
import background from '../img/welcome-background.jpg';
import { Box } from '@material-ui/core';

class WelcomePage extends Component {
    render = () => {
        return <Box><BackgroundImage src={background} zIndex={1}/></Box>;
    }
}

export default WelcomePage;