export const HOME_URL = '/';
export const LOGIN_URL = '/login';
export const LOGOUT_URL = '/logout';
export const PASSWORD_URL = '/password';
export const USER_URL = '/users';
export const CUSTOMER_URL = '/customers';
export const SETUP_URL = '/setup';
export const DEVICE_TYPE_URL = '/device_type';
export const EVENT_TYPE_URL = '/event_type';
export const EVENT_TYPE_ICON_URL = '/markers';
export const EVENT_TYPE_ICON_LIST_FILE = `${EVENT_TYPE_ICON_URL}/markers.csv`;
export const EVENT_TYPE_ICON_BACKGROUND = `${EVENT_TYPE_ICON_URL}/map-background.png`;
export const EVENT_TYPE_ICON_NO_MARKER = `${EVENT_TYPE_ICON_URL}/no-marker.png`;
export const COMMAND_URL = '/command';
export const SIMCARD_URL = '/simcard';
export const FIRMWARE_URL = '/firmware';
export const OTA_URL = '/ota';
export const DEVICE_URL = '/device';
export const DEVICE_COMMAND_URL = '/device_command';

/*
export const MAP_URL = '/map';
export const INSTANCE_URL = '/instances';
export const RTU_URL = '/rtus';
export const ASSEMBLY_URL = '/assemblies';
export const DELIVERY_URL = '/deliveries';

export const TRIGGERS_URL = '/triggers';
export const EVENTS_URL = '/events';
export const COMMAND_URL = '/commands';
export const API_KEY_URL = '/api_keys';
*/
export const PASSWORD_RECOVER_URL = '/passwordRecover';