import React from 'react'
import dayjs from 'dayjs';
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';
import { Icon, Chip } from '@material-ui/core';
import AbstractAbmPage from './AbstractAbmPage.js';
import OtaForm from '../components/forms/OtaForm.js';
import { API_OTA, API_OTA_ACTIVATE, API_OTA_CREATE, API_OTA_DELETE, API_OTA_LIST } from '../constants/api.js';
import OtaStatus from '../components/icondisplay/OtaStatus.js';

class OtaPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = OtaForm;
        this.strings = {
            createItem: 'Nuevo OTA',
            editItem: '',
            itemCreationSuccess: 'OTA registrado exitosamente',
            itemUpdateSuccess: '',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el OTA seleccionado',
                save: 'Ocurrió un error al registrar el OTA. Intente nuevamente.',
                E01: 'Debe seleccionar un firmware',
                E02: 'No existe el firmware seleccionado',
                E03: 'Debe seleccionar al menos un equipo',
            }
        }
        
        this.urls = {
            list: API_OTA_LIST,
            item: API_OTA,
            toggle: API_OTA_ACTIVATE,
            delete: API_OTA_DELETE,
            create: API_OTA_CREATE,
            update: null,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 5,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'download_file', field: 'download_file', title: 'Archivo', sorting: false, render: (e) => {
                    return (<a href={e.firmware.uri}><Icon title="Descargar archivo">get_app</Icon></a>);
                }},
                {id: 'mac', field: 'device.mac', title: 'Mac', sorting: true},
                {id: 'name', field: 'device.name', title: 'Nombre', sorting: true},
                {id: 'firmware', field: 'firmware.description', title: 'Firmware', sorting: true},
                {id: 'type', field: 'device.device_type.name', title: 'Tipo', sorting: true},
                {id: 'created_at', field: 'created_at', title: 'Fecha de creación', sorting: true, render: e =>  (e.created_at && dayjs(e.created_at).format ( 'DD/MM/YYYY HH:mm')) },
                {id: 'last_download_at', field: 'last_download_at', title: 'Fecha de última descarga', sorting: true, render: e => ( e.last_download_at && dayjs(e.last_download_at).format ( 'DD/MM/YYYY HH:mm'))},
                {id: 'end_download_at', field: 'end_download_at', title: 'Fecha de fin de descarga', sorting: true, render: e => ( e.end_download_at && dayjs(e.end_download_at).format ( 'DD/MM/YYYY HH:mm'))},                
                {id: 'status', field: 'status', title: 'Estado', render: (e) => <OtaStatus ota={e} />}
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nuevo OTA',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Pausar':'Reanudar',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar el OTA de ${element.device.mac}? `) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(OtaPage));


