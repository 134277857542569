import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import { API_USER_FORM } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import { Grid } from '@material-ui/core';
import { USER_IS_ADMIN, USER_IS_ROOT } from '../../constants/profiles';

class UserForm extends Component {
    constructor (props) {
        super(props);
        
        this.state = {
            customers: null,
            user_types: null,
            min_password_length: 8,
        };
        
        this.populateForm();

    }

    populateForm = () => {
        loggedUserServerFetch (API_USER_FORM, 'get', {}, {}, {}).then ( (response) => {                
            this.setState({...this.state, customers: response?.data?.customer, user_types: response?.data?.user_type, min_password_length: response?.data?.min_password_length||8});

        }).catch ( (error) => {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario de usuarios');
            this.props.onClose();
        })

    }

    buildSchema = (values) => {
        let  userTypeOptions = {};
        this.state.user_types && this.state.user_types.forEach ( ut => userTypeOptions[ut.id] = ut.description);

        let customerOptions = {};
        this.state.customers && this.state.customers.forEach ( c => customerOptions[c.id] = c.description);

        let schema = {fields: [
            {
                key: 'id',
                name: 'id',
                type: 'hidden',
            },
            {
                key: 'screen_name',
                name: 'screen_name',
                type: 'text',
                label: 'Nombre y apellido',
                required: true,
                disabled: false,
                autoFocus: true,
            },
            {
                key: 'email',
                name: 'email',
                type: 'email',
                label: 'E-mail',
                required: true,
                disabled: false,
                autoFocus: false,
            },            
            {
                name: 'password',
                type: 'password',
                label: `Contraseña (Al menos ${this.state.min_password_length} caracteres)`,
                minLength: this.state.min_password_length,
                disabled: false,
                requiredIf: {
                    match:'any',
                    rules:[
                        {field: 'id', value: null, operator:'isNull'},
                    ]
                }
            },

            {
                key: 'user_type',
                name: 'user_type_id',
                label: 'Perfil',
                required: true,
                disabled: false,
                autoFocus: false,
                type: 'select',
                options: userTypeOptions,
            },
            {
                key: 'customer',
                name: 'customer_id',
                label: 'Cliente',
                required: true,
                disabled: false,
                autoFocus: false,
                type: 'select',
                options: customerOptions,
                hiddenIf: {
                    match: 'any',
                    rules: [
                        { field: 'user_type_id', value:[`${USER_IS_ROOT}`,USER_IS_ROOT, `${USER_IS_ADMIN}`,USER_IS_ADMIN, ], operator: 'contains' },
                        { field: 'user_type_id', value: null, operator: 'isNull' }
                    ]
                },
            }

        ]};
        return schema;
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        const schema = this.buildSchema;
        if (this.state.customers === null && this.state.user_types === null) return this.renderLoading();
        return <AbstractForm schema={schema} {...this.props} />
    }
}

export default UserForm;