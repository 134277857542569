import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import { API_SIMCARD_FORM } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import { Grid } from '@material-ui/core';

class SimcardForm extends Component {
    constructor (props) {
        super(props);

        props.values.mobile_broadband_provider_id = props?.values?.mobile_broadband_provider?.id;
        
        this.state = {
            broadbandProviders: [],
        };
        
        this.populateForm();
    }

    populateForm = () => {
        loggedUserServerFetch (API_SIMCARD_FORM, 'get', {}, {}, {}).then ( (response) => {                
            this.setState({...this.state, broadbandProviders: response?.data?.mobile_broadband_provider});

        }).catch ( (error) => {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario de tarjetas SIM');
            this.props.onClose();
        })

    }

    buildSchema = (values) => {
        let broadbandOptions = {};
        this.state.broadbandProviders && this.state.broadbandProviders.forEach ( d => broadbandOptions[d.id] = d.description);

        let schema = {fields: [
            {
                key: 'id',
                name: 'id',
                type: 'hidden',
            },
            {
                key: 'imsi',
                name: 'imsi',
                type: 'number',
                label: 'IMSI',
                required: true,
                disabled: false,
                autoFocus: true,
                min: 0,
                step: 1,
            },
            {
                key: 'phone',
                name: 'phone',
                type: 'number',
                label: 'Teléfono',
                required: true,
                disabled: false,
                autoFocus: false,
                min: 0,
                step: 1,
            },            
            {
                key: 'mobile_broadband_provider_id',
                name: 'mobile_broadband_provider_id',
                label: 'Proveedor de telefonía',
                required: true,
                disabled: false,
                autoFocus: false,
                type: 'select',
                options: broadbandOptions,
            },
            {
                key: 'active',
                name: 'active',
                type: 'switch',
                label: 'Activo',
            }
        ]};
        return schema;
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        const schema = this.buildSchema;
        if (this.state.customers === null && this.state.user_types === null) return this.renderLoading();
        return <AbstractForm schema={schema} {...this.props} />
    }
}

export default SimcardForm;