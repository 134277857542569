import React from 'react'
import PropTypes from 'prop-types';

const MarkerPreview = (props) => {
    const {backgroundUrl, markerUrl} = props;

    return (                    
        <div 
            class="text-center" 
            style={ {backgroundImage: `url('${backgroundUrl}')`, width: '320px', height: '120px', backgroundRepeat:'no-repeat', backgroundSize: 'cover'}}>
                <img 
                    src={ markerUrl } 
                    id="icon-Preview" 
                    style={{position: 'relative', top: '50px', left: '50%'}} 
                    alt="Marker preview"
                />
        </div>                
    )
}

MarkerPreview.propTypes = {
    backgroundUrl: PropTypes.string.isRequired,
    markerUrl: PropTypes.string.isRequired,    
}

export default MarkerPreview