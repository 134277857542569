import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import { API_COMMAND_FORM } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import { Grid } from '@material-ui/core';

class CommandForm extends Component {
    constructor (props) {
        super(props);

        props.values.device_type_id = props?.values?.device_type?.id;
        
        this.state = {
            deviceTypes: [],
        };
        
        this.populateForm();
    }

    populateForm = () => {
        loggedUserServerFetch (API_COMMAND_FORM, 'get', {}, {}, {}).then ( (response) => {                
            this.setState({...this.state, deviceTypes: response?.data?.device_type});

        }).catch ( (error) => {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario de comandos');
            this.props.onClose();
        })

    }

    buildSchema = (values) => {
        let deviceTypesOptions = {};
        this.state.deviceTypes && this.state.deviceTypes.forEach ( d => deviceTypesOptions[d.id] = d.description);

        let schema = {fields: [
            {
                key: 'id',
                name: 'id',
                type: 'hidden',
            },
            {
                key: 'description',
                name: 'description',
                type: 'text',
                label: 'Descripción',
                required: true,
                disabled: false,
                autoFocus: true,
            },
            {
                key: 'cmd',
                name: 'cmd',
                type: 'text',
                label: 'Comando',
                required: true,
                disabled: false,
                autoFocus: false,
            },            
            {
                key: 'deviceTypeId',
                name: 'device_type_id',
                label: 'Tipo de dispositivo',
                required: true,
                disabled: false,
                autoFocus: false,
                type: 'select',
                options: deviceTypesOptions,
            },
            {
                key: 'active',
                name: 'active',
                type: 'switch',
                label: 'Activo',
            }
        ]};
        return schema;
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        const schema = this.buildSchema;
        if (this.state.customers === null && this.state.user_types === null) return this.renderLoading();
        return <AbstractForm schema={schema} {...this.props} />
    }
}

export default CommandForm;