import React from 'react'
import { Icon } from '@material-ui/core';
import PropTypes from 'prop-types';

const ActiveIcon = (props) => {
    const {active, activeLabel, inactiveLabel} = props;
    
    const txt = active?activeLabel:inactiveLabel;
    const deco = active?'primary':'secondary';
    const icon = active?'play_circle_outline':'pause_circle_outline';
    return (<Icon color={deco}  title={txt}>{icon}</Icon>);

}

ActiveIcon.propTypes = {
    active: PropTypes.bool.isRequired,
    activeLabel: PropTypes.string.isRequired,
    inactiveLabel: PropTypes.string.isRequired,
}

export default ActiveIcon;