import React from 'react'
import { Grid, Container } from '@material-ui/core';
import Form from '../form-builder/form';

const SimpleForm = (props) => {
    if (props.hidden) {
        return (null);
    } else {
        return (
            <Container>
                <Grid item xs={12}>
                    <Form
                        schema={props.schema}
                        values={props.values||{}}
                        onSubmit={props.onSubmit}
                        onCancel={props.onCancel}
                        children={props.children||[]}
                        disableSaveButton={props.disableSaveButton||false}
                        buttons={{
                            align: 'flex-end',
                        }}
                        />
                </Grid>
            </Container>            
        );
    }
}

export default SimpleForm;