import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import { API_OTA_FORM } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import { Grid } from '@material-ui/core';

class OtaForm extends Component {
    constructor (props) {
        super(props);
        
        this.state = {
            firmwares: [],
            devices: [],
            loading: true,

            version: null,
            timestamp: null,
            crc32: null,
            type: null,
        };                
    }

    componentDidMount = async () => {

        try {
            const response = await loggedUserServerFetch (API_OTA_FORM, 'get', {}, {}, {});

            const firmwares = response?.data?.firmwares || [];
            const devices = response?.data?.devices || [];

            let errMsg = false;

            if ( firmwares.length === 0 && devices.length === 0) {
                errMsg = 'No hay firmwares ni equipos cargados';

            } else if ( firmwares.length === 0 ) {
                errMsg = 'No hay firmwares cargados';

            } else if ( devices.length === 0 ) {
                errMsg = 'No hay equipos cargados';

            }

            if (errMsg !== false) {
                toast.error(errMsg, 'fetch_error');
                this.props.onClose();
                return;
            }
    
            this.setState({...this.state, firmwares, devices, loading: false});

        } catch (error) {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario de OTA');
            this.props.onClose();
            
        }
    }

    buildSchema = (values) => {
        let fwOptions = {}; 
        let targetPlataform = [];
    
        //Armo la lista de FW
        this.state.firmwares.forEach(element => {
            const str_plat = element.device_types.map( e => e.name).join(', ');
            fwOptions[element.id] = `${element.description} - Versión ${element.version} - ${str_plat}` ;
            if (parseInt(values.firmware) === element.id) targetPlataform = element.device_types_id;
        });
    
        //Filtro la lista de equipos que matcheen con la plataforma del FW
        const deviceOptions = [];
        if (targetPlataform) {
            this.state.devices.forEach (element => {
                targetPlataform.includes( parseInt(element?.device_type?.id)) && deviceOptions.push({label: element.mac, value: element.id});
            })
        }
    
        const schema =  {
            fields: [            
                {
                    key: 'firmware',
                    name: 'firmware',
                    type: 'select',
                    label: 'Versión de firmware',
                    required: true,
                    disabled: false,
                    autoFocus: true,
                    options: fwOptions,
                },
                {
                    key: 'device',
                    name: 'device',
                    type: 'multiselect-autocomplete',
                    label: 'Equipos',
                    required: true,
                    disabled: false,
                    idField: 'id',
                    labelField: 'description',
                    options: deviceOptions,
                },
                {
                    key: 'active',
                    name: 'active',
                    type: 'switch',
                    label: 'Activo',
                    required: false,
                    disabled: false,
                }
            ]
        };
        return schema;
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        const schema = this.buildSchema;
        if (this.state.loading ) return this.renderLoading();
        return (
            <AbstractForm 
                schema={schema} 
                {...this.props}
            />)
    }
}

export default OtaForm;