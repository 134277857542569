import React from 'react'
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';

import AbstractAbmPage from './AbstractAbmPage.js';
import ActiveIcon from '../components/icondisplay/ActiveIcon.js';
import { API_SIMCARD, API_SIMCARD_ACTIVATE, API_SIMCARD_CREATE, API_SIMCARD_DELETE, API_SIMCARD_EDIT, API_SIMCARD_LIST } from '../constants/api.js';
import SimcardForm from '../components/forms/SimcardForm.js';

class SimcardPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = SimcardForm;
        this.strings = {
            createItem: 'Nueva tarjeta SIM',
            editItem: 'Editar tarjeta SIM',
            itemCreationSuccess: 'Tarjeta SIM creada exitosamente',
            itemUpdateSuccess: 'Tarjeta SIM actualizada exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra la tarjeta SIM seleccionada',
                save: 'Ocurrió un error al guardar la tarjeta SIM. Intente nuevamente.',
                E01: 'Ya existe una tarjeta SIM con el número de IMSI',
                E02: 'Ya existe una tarjeta SIM con el número de teléfono',
                E03: 'La tarjeta sim se encuentra en uso y no se puede eliminar',
            }
        }
        
        this.urls = {
            list: API_SIMCARD_LIST,
            item: API_SIMCARD,
            toggle: API_SIMCARD_ACTIVATE,
            delete: API_SIMCARD_DELETE,
            create: API_SIMCARD_CREATE,
            update: API_SIMCARD_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'imsi', field: 'imsi', title: 'IMSI', sorting: true},
                {id: 'phone', field: 'phone', title: 'Teléfono', sorting: true},
                {id: 'provider', field: 'mobile_broadband_provider.description', title: 'Proveedor', sorting: true},
                {id: 'device', field: 'device.mac', title: 'Equipo', sorting: true},
                {id: 'customer', field: 'device.customer.description', title: 'Cliente', sorting: true},
                {id: 'active', field: 'active', title: 'Activo', render: (e) => <ActiveIcon active={!!e.active} activeLabel="Activo" inactiveLabel="Inactivo"/>}
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nueva tarjeta SIM',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Desactivar':'Activar',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => {
                    return ({
                        icon: 'edit',
                        tooltip: 'Editar tarjeta SIM',
                        onClick: () => {this.handleEdit(element.id) },
                    })
                },
                (element) => ({
                    icon: 'delete',
                    disabled: element?.is_assigned,
                    tooltip: element?.is_assigned?'No se puede eliminar una tarjeta en uso':'Eliminar tarjeta SIM',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar la tarjeta SIM ${element.imsi}?`) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(SimcardPage));


