import React from 'react'
import { Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FEATURE_ETH, FEATURE_GPS, FEATURE_SIM } from '../../constants/device_features';
import { green, grey, orange, red } from '@material-ui/core/colors';
import { MAX_GPS_AGING } from '../../constants/ui';

const color = {
    OK: green[500],
    FAIL: red[500],
    UNKNOWN: grey[500],
    NODATA: orange[500],
}

const generateIcon = ( key, title, color, icon) => {
    return (<Icon title={title} style={{color: color}} key={key}>{icon}</Icon>);

}

const DeviceStatusIcons = (props) => {
    const result = [];
    //Análisis de features
    const device = props?.device;
    const features = (device?.device_type?.features) || [];
    const hasGps = features.includes(FEATURE_GPS);
    const hasSim = features.includes(FEATURE_SIM);
    const hasEth = features.includes(FEATURE_ETH);

    let tmpColor = color.UNKNOWN;

    //¿Online?
    const isOnline = true;
    isOnline && result.push (generateIcon('linkicon', isOnline?`En línea`:'Fuera de línea', isOnline?color.OK:color.FAIL, isOnline?'link_icon':'link_off_icon'));

    //Features
    if (hasSim) {
        let selectedColor = color.UNKNOWN;
        let title = 'GSM (Tarjeta SIM no instalada)';

        //GRIS: Soporta sim pero no está instalada
        //Naranja: Soporta sim, está instalada y no hay datos
        //Verde: Soporta sim, está instalada y está conectada
        //Rojo: Soporta sim, está instalada y no está conectada

        if ( device.simcard !== null && device.last_event === null ) {
            selectedColor = color.NODATA;
            title = 'GSM (Tarjeta SIM instalada, sin información de estado)';

        } else if ( device.simcard !== null && device.last_event !== null && false ) {
            //TODO: Verificar conexión OK
            selectedColor = color.OK;
            title = 'GSM (Tarjeta SIM instalada, conectada)';

        } else if ( device.simcard !== null && device.last_event !== null && false ) {
            //TODO: Verificar conexión ERR
            selectedColor = color.ERROR;
            title = 'GSM (Tarjeta SIM instalada, no conectada)';
        }

        result.push ( generateIcon('gsm_icon', title, selectedColor, 'sim_card'));
    }

    if (hasEth) {
        const eth_status = props?.device?.last_state?.ethernet?.link || null;
        
        tmpColor = (eth_status === null) ? color.UNKNOWN : (eth_status ? color.OK : color.FAIL);
        const tmp = (eth_status === null) ? 'Sin información' : (eth_status ? 'Conectado' : 'Desconectado');
        result.push ( generateIcon('eth_icon', `Puerto Ethernet: ${tmp}`, tmpColor, 'public'));
    }

    if (hasGps) {
        const aging = props?.device?.last_state?.gps?.aging || null;
        const is_old_position = (props?.device?.last_state?.gps?.aging || 999999999) > MAX_GPS_AGING;

        tmpColor = (aging === null) ? color.UNKNOWN : (is_old_position ? color.FAIL : color.OK);
        result.push ( generateIcon('gps_icon', 'GPS', tmpColor, 'gps_fixed'));
    }

    //OTA
    const hasPendingOta = props?.device?.ota !== null;
    hasPendingOta && result.push (generateIcon('ota_icon', `OTA Pendiente - FW: ${props.device.ota.firmware.description} - Ver: ${props.device.ota.firmware.version}`, null, 'memory_icon'));

    //Comandos    
    if (props?.device?.enqueued_command !== null) {
        const pendingCommand = props?.device?.enqueued_command?.description || 'desconocido';
        const isPaused = props?.device?.enqueued_command?.is_paused;
        const isUploaded = props?.device?.enqueued_command?.is_uploaded;

        tmpColor = null;
        let tmpText = `Comando pendiente: ${pendingCommand}`;

        if (isPaused) {
            tmpColor = color.NODATA;
            tmpText = `${tmpText} (Pausado)`;

        } else if (isUploaded) {
            tmpColor = color.OK;
            tmpText = `${tmpText} (Enviado al equipo)`;
    
        }
        result.push ( generateIcon ('command_icon', tmpText, tmpColor, 'settings_icon'));
    }

    return result;
}

DeviceStatusIcons.propTypes = {
    device: PropTypes.object.isRequired,
    
}

export default DeviceStatusIcons;