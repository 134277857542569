import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { Grid,  DialogContent, TextField,  } from '@material-ui/core';
import { Button, Box } from '@material-ui/core';
import { ArrowBack, Save } from '@material-ui/icons';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { pageStyle } from "../../styles/styles.js";
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import { EVENT_TYPE_ICON_BACKGROUND, EVENT_TYPE_ICON_LIST_FILE, EVENT_TYPE_ICON_URL } from '../../constants/url';
import DialogIconPicker from './event_type/DialogIconPicker';
import MarkerPreview from './event_type/MarkerPreview';
import { API_EVENT_TYPE_FORM } from '../../constants/api.js';
import MultiSelectAutocomplete from '../form-builder/fields/multiselect-autocomplete';

class EventTypeForm extends Component {
    constructor (props) {
        super(props);
        
        const deviceTypes = (props?.values?.device_type || []).map( e => e.id);

        this.state = {
            icons: [],
            categories: [],
            iconsLoaded: false,

            deviceTypesDB: [],
            deviceTypesLoaded: false,
            
            hasChanged: false,

            displayIconPicker: false,

            //form
            id: props?.values?.id||null,
            description: props?.values?.description||'',
            value: props?.values?.value||0,
            deviceTypes: props?.values?.deviceTypes || deviceTypes,
            icon: props?.values?.icon_url||null,            
            color: props?.values?.color||null,
            icon_updated: false,
        };        
    }

    componentDidMount = () => {
        this.populateForm();
    }

    populateForm = () => {
        loggedUserServerFetch (EVENT_TYPE_ICON_LIST_FILE, 'get', {}, {}, {}).then ( (response) => {
            let icons = {};
            let categories = [];
            response.data.split("\n").forEach( e => {
                const [category, file] = e.split(',');
                icons[category]?icons[category].push(file):icons[category]=[file];
                (category.length > 0) && categories.push(category);
            });
            categories = [...new Set(categories)];
            this.setState({...this.state, icons: icons, categories: categories, iconsLoaded: true});

        }).catch ( (error) => {
            console.error (error);
            toast.error ('Ocurrió un error consultando la base de datos de íconos');
            this.props.onClose();
        })

        loggedUserServerFetch (API_EVENT_TYPE_FORM, 'get', {}, {}, {}).then ( (response) => {
            const deviceTypes = response?.data?.device_type;            
            this.setState({...this.state, deviceTypesDB: deviceTypes, deviceTypesLoaded: true});

        }).catch ( (error) => {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario de tipos de eventos');
            this.props.onClose();
        })
    }

    handleCloseChooseIcon = () => {
        this.setState ( { ...this.state, displayIconPicker: false});
    }

    handleChooseIcon = () => {        
        this.setState ( { ...this.state, displayIconPicker: true});

    }

    handleOnChangeIcon = ( color, icon) => {
        this.setState ( {...this.state, displayIconPicker: false, icon, color, hasChanged: true, icon_updated: true});
    }

    handleOnChangeField = (name, value) => {
        const newState = {...this.state};
        newState[name] = value;
        newState.hasChanged = true;
        this.setState(newState);
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        const form = {
            id: this.state.id,
            description: this.state.description,
            value: this.state.value,
            deviceTypes: this.state.deviceTypes,
            icon: this.state.icon,
            color: this.state.color,
            icon_updated: this.state.icon_updated,
        };

        if (this.props.isNew) {
            this.props.onCreate (form);
    
        } else {
            this.props.onUpdate (form, form.id);
        }
    }

    handleBack = () => {
        if (this.state.hasChanged) {
            Swal.fire ({
                title: 'El formulario tiene cambios',
                text: '¿Desea salir sin guardar?',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sí, salir',
                cancelButtonText: 'Quedarme aquí',

            }).then( (result) => {
                result.value && this.setState( {...this.state, changed: false});
                result.value && this.props.onClose();
            });

        } else {
            this.props.onClose();
        }
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        if (! this.state.iconsLoaded  || ! this.state.deviceTypesLoaded ) return this.renderLoading();


        const deviceTypeOptions = this.state.deviceTypesDB.map( e => ({value: e.id, label: e.description}) );
        const disableSaveButton = this.props?.disableSaveButton || (this.state.icon === null) || (this.state.deviceTypes.length === 0);

        return (
            <DialogContent>
                <form method="post" onSubmit={this.handleSubmit}>
                    <Grid container direction={"column"} spacing={5}>
                        <Grid item>
                            <TextField
                                id="description"
                                key="description"
                                label="Nombre del evento"
                                value = { this.state?.description }
                                onChange = { (v) => this.handleOnChangeField('description', v?.target?.value) }
                                fullWidth
                                required
                                autoFocus
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                id="value"
                                key="value"
                                label="Valor"
                                type="number"
                                value={this.state?.value}
                                onChange = { (v) => this.handleOnChangeField('value', v?.target?.value) }
                                InputLabelProps={{min: 0, step: 1}}
                                fullWidth
                                required
                            />                            
                        </Grid>

                        <Grid item>
                            <MultiSelectAutocomplete 
                                key="device-types"
                                label="Tipos de dispositivos asociados"
                                options={deviceTypeOptions}
                                name="deviceTypes"
                                value={this.state.deviceTypes}
                                onChange={ this.handleOnChangeField }
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >                        
                        { this.state.icon && <>
                            <Grid item>
                                <MarkerPreview 
                                    backgroundUrl = { EVENT_TYPE_ICON_BACKGROUND }
                                    markerUrl = { this.state.icon }
                                />
                            </Grid>
                            <Grid item>
                                <Button 
                                    type="button"
                                    variant="contained" 
                                    color="default"
                                    title="Cambiar ícono para el marcador"
                                    onClick={ this.handleChooseIcon }
                                >Cambiar ícono del marcador</Button>
                            </Grid>
                        </>}

                        { !this.state.icon &&<Grid item><Button 
                            type="button"
                            variant="contained" 
                            color="default"
                            title="Seleccionar ícono para el marcador"
                            onClick={ this.handleChooseIcon }
                        >Seleccionar ícono del marcador</Button></Grid>
                        }

                        { this.state.displayIconPicker && <Grid item><DialogIconPicker
                            icons = { this.state.icons }
                            categories = { this.state.categories }
                            baseUrl = { EVENT_TYPE_ICON_URL }
                            backgroundUrl = { EVENT_TYPE_ICON_BACKGROUND }
                            onSubmit = { this.handleOnChangeIcon }
                            onCancel = { this.handleCloseChooseIcon }
                        /></Grid>}
                    </Grid>                                        

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            <Box pt={3} pb={1}>
                                <Button 
                                    type="button"
                                    variant="contained" 
                                    color="default"
                                    title="Cerrar"
                                    onClick={this.handleBack}
                                    startIcon={<ArrowBack />}
                                >Volver</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    title="Guardar"
                                    startIcon={<Save />}
                                    disabled={disableSaveButton}
                                >Guardar</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        );
    }
}


EventTypeForm.propTypes={    
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    disableSaveButton: PropTypes.bool,
    isNew: PropTypes.bool.isRequired,
    values: PropTypes.object,
    schema: PropTypes.any.isRequired,
}

export default withStyles(pageStyle)(EventTypeForm);