import React from 'react'
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import Chip from '@material-ui/core/Chip';
import { getUpdateInterval } from '../redux/reducers/ui';

import AbstractAbmPage from './AbstractAbmPage.js';
import { API_EVENT_TYPE, API_EVENT_TYPE_ACTIVATE, API_EVENT_TYPE_CREATE, API_EVENT_TYPE_DELETE, API_EVENT_TYPE_EDIT, API_EVENT_TYPE_LIST } from '../constants/api.js';
import EventTypeForm from '../components/forms/EventTypeForm.js';
import ActiveIcon from '../components/icondisplay/ActiveIcon.js';

class EventTypePage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = EventTypeForm;
        this.strings = {
            createItem: 'Nuevo tipo de evento',
            editItem: 'Editar tipo de evento',
            itemCreationSuccess: 'Tipo de evento creado exitosamente',
            itemUpdateSuccess: 'Tipo de evento actualizado exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el tipo de evento seleccionado',
                save: 'Ocurrió un error al guardar el tipo de evento. Intente nuevamente.',
            }
        }
        
        this.urls = {
            list: API_EVENT_TYPE_LIST,
            item: API_EVENT_TYPE,
            toggle: API_EVENT_TYPE_ACTIVATE,
            delete: API_EVENT_TYPE_DELETE,
            create: API_EVENT_TYPE_CREATE,
            update: API_EVENT_TYPE_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'asc',
            columns: [ 
                {id: 'description', field: 'description', title: 'Descripción', sorting: true},
                {id: 'device_types', field: 'device_types', title: 'Dispositivos', sorting: false, render: e=> e.device_type.map ( e => <Chip label={e.description} />) },
                {id: 'value', field: 'value', title: 'Valor', sorting: false},
                {id: 'icon', field: 'icon', title: 'Ícono', sorting: false, render: e => <img src={e.icon_url} alt="Marker" key={`marker_icon_${e.id}`} />},
                {id: 'active', field: 'active', title: 'Activo', render: (e) => <ActiveIcon active={!!e.active} activeLabel="Cliente activo" inactiveLabel="Cliente inactivo"/>}
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nuevo tipo de evento',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Desactivar tipo de evento':'Activar tipo de evento',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => {
                    return ({
                        icon: 'edit',
                        tooltip: 'Editar tipo de evento',
                        onClick: () => {this.handleEdit(element.id) },
                    })
                },
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar tipo de evento',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar al tipo de evento ${element.description}?`) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(EventTypePage));


