import { ACTION_SET_FOCUS_STATUS } from '../actions';
import { createSelector } from 'reselect';
import { FOCUS_REFRESH_TIMEOUT, NO_FOCUS_REFRESH_TIMEOUT } from '../../constants/ui';

export const uiReducer = (state = {}, action) => {
    let result = null;
    switch (action.type) {
        case ACTION_SET_FOCUS_STATUS:
            result = {
                focus: (!!action.payload),
                update_interval: (!!action.payload)?FOCUS_REFRESH_TIMEOUT:NO_FOCUS_REFRESH_TIMEOUT,
            }
            break;
    
        default:
            result = state;
            break;
    }
    return result;
}

export const isFocused = createSelector(
    (state) => state.ui,
    ui => ui.focus
);

export const getUpdateInterval = createSelector(
    (state) => state.ui,
    ui => ui.update_interval
);