import { store } from "../../redux/store";
import { getUserPermission } from "../../redux/reducers/users";

import { menuGenerator } from "./menuGenerator";

export const getMenuForCurrentPermision = () => {
    const state = store?.getState();
    let permission = getUserPermission(state);
    permission = Array.isArray(permission)? [...permission].shift() : permission;
    return menuGenerator(permission);
}
