import React  from 'react';
import { MultiSelect } from "react-multi-select-component";


const processChange = (v, name, onChange) => {
    let result = [];

    for (let i = 0; i < v.length; i++) result.push (v[i].value);

    onChange(name, result);
}

const filterOptions = (options, filter) => {    
    if (!filter) return options;
    const re = new RegExp(filter, "i");
    return options.filter(({ label }) => label && label.match(re));
};

const MultiSelectAutocomplete = ({onChange, value, options, ...props}) => {
    const selected = [];
    value = value || [];
    for (let i = 0; i < options.length; i++) {
        const item = options[i];        
        if (value.includes(item.value)) selected.push({...item});
    }
    return (        
        <React.Fragment>
            <br />
            <label>{props.label||''}</label>
            <MultiSelect
                options={options}
                value={selected}
                onChange={(v) => processChange(v, props.name, onChange)}
                labelledBy={"Select"}
                overrideStrings={{
                    "allItemsAreSelected": "Todos los elementos seleccionados",
                    "clearSearch": "Borrar búsqueda",
                    "noOptions": "No hay resultados",
                    "search": "Buscar",
                    "selectAll": "Seleccionar todo",
                    "selectAllFiltered": "Seleccionar todo (Filtrado)",
                    "selectSomeItems": "Seleccionar..."
                }}
                filterOptions={filterOptions}
            />
        </React.Fragment>        
    );
}
export default MultiSelectAutocomplete;