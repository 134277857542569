import React from 'react'
import Input from './fields/input';
import Switch from './fields/switch';
import Checkbox from './fields/checkbox';
import Select from './fields/select';
import Radio from './fields/radio';
import MultiSelect from './fields/multiselect';
import { DropzoneArea } from 'material-ui-dropzone';
import MultiSelectAutocomplete from './fields/multiselect-autocomplete';
import MacScanner from './fields/mac-scanner';
import MarkerPreview from './fields/MarkerPreview';
import AddressMap from './fields/AddressMap';

const checkValue = (v1, oper, v2)  => {
    let result = false;
    switch (oper) {
        case '===':
        case '==':
            result = (v1 === v2);
            break;
            
        case '!==':
        case '!=':
            result = (v1 !== v2)
            break;

        case 'contains':
            if (! Array.isArray(v1)) v1 = [v1];
            if (! Array.isArray(v2)) v2 = [v2];
            result = v1.some( v => v2.includes(v));
            break;

        case 'notcontains':
            if (! Array.isArray(v1)) v1 = [v1];
            if (! Array.isArray(v2)) v2 = [v2];
            result = ! v1.some( v => v2.includes(v));
            break;

        case 'containsall':
            if (! Array.isArray(v1)) v1 = [v1];
            if (! Array.isArray(v2)) v2 = [v2];
            result = v1.every( v => v2.includes(v));
            break;

        case 'containsnone':
            if (! Array.isArray(v1)) v1 = [v1];
            if (! Array.isArray(v2)) v2 = [v2];
            result = ! v1.every( v => v2.includes(v));
            break;

        case 'isnull':
            result = (v1 === null);
            break;
        
        case 'isnotnull':
            result = (v1 !== null);
            break;
        
        case 'isempty': 
            if (! Array.isArray(v1)) v1 = [v1];
            result = v1.length === 0;
            break;

        case 'isnotempty': 
            if (! Array.isArray(v1)) v1 = [v1];
            result = v1.length > 0;
            break;

        case '<':
            result = v1 < v2;
            break;

        case '<=':
            result = v1 <= v2;
            break;

        case '>':
            result = v1 > v2;
            break;
        case '>=':
            result = v1 >= v2;
            break;
         
        default:
            result = false;
            break;
    }

    return result;
}

const processDependencies = ( values, props ) => {
    const {match, rules} = props;
    let result = false;
    let tempResult = [];

    rules.forEach ( rule => {
        const cv = ( typeof values[rule.field] == 'undefined')?null:values[rule.field];
        tempResult.push( checkValue (cv, rule.operator.toLowerCase(), rule.value));
    })

    switch (match.toLowerCase()) {
        case 'any':
        case 'some':
            result = tempResult.some( v => v === true );
            break;

        case 'all':
        case 'every':
            result = tempResult.every( v => v === true );
            break;

        default:
            result = false;
            break;
    }
    return result;
}

const RenderField = ( {onChange, values, ...aprops} ) => {
    let result = null;
    let props = {...aprops};
    const value = values[props.name]||'';
    
    if (props?.hiddenIf) {
        props.hidden = processDependencies(values, props.hiddenIf);
        delete(props.hiddenIf);
    }
    
    if (props?.requiredIf) {
        props.required = processDependencies(values, props.requiredIf);
        delete (props.requiredIf);
    }

    if (props.hidden) return null;

    switch (props.type.toLowerCase()) {
        case 'group':
            result = <Group key={`group_${props.name}`} onChange={onChange} values={values} title={props.title||''} fields={props.fields} />
            break;
        
        case 'radio':
            result =
                <Radio
                    key={`field_${props.name}`}
                    value={ value }
                    onChange={onChange}
                    {...props}
                />
            break;

        case 'hidden':
            result = 
                <input 
                    type="hidden" 
                    key={`field_${props.name}`}
                    id={`field_${props.name}`}
                    name={props.name}
                    value={ !! value }
                    onChange={ (ev) => onChange(ev.target.name, ev.target.value) }
                />            
            break;

        case 'multiselect':
            result = 
            <MultiSelect
                key={`field_${props.name}`}
                value={ value }
                onChange={onChange}
                {...props}
            />
            break;

        case 'multiselect-autocomplete':
                result = 
                <MultiSelectAutocomplete
                    key={`field_${props.name}`}
                    value={ value }
                    onChange={onChange}
                    {...props}
                />
                break;
    
        case 'select':
            result = 
                <Select
                    key={`field_${props.name}`}
                    value={ value }
                    onChange={onChange}
                    {...props}
                />
            break;

        case 'checkbox':
            result = 
                <Checkbox
                    key={`field_${props.name}`}
                    value={ value }
                    onChange={onChange}
                    {...props}
                />
            break;

        case 'switch':
            result = 
                <Switch 
                    key={`field_${props.name}`}
                    value={ value }
                    onChange={onChange}
                    {...props}
                />
            break;

        case 'number':
            let inputProps = {};
            if (typeof props.min !== 'undefined') inputProps.min = props.min;
            if (typeof props.max !== 'undefined') inputProps.max = props.max;
            if (typeof props.step !== 'undefined') inputProps.step = props.step;
            result = 
                <Input
                    key={`field_${props.name}`}
                    value={ value }
                    onChange={onChange}
                    inputProps={inputProps}
                    {...props}
                />
            break;

        case 'textarea': 
            result = 
                <Input
                    key={`field_${props.name}`}
                    multiline
                    value={ value }
                    onChange={onChange}
                    {...props}
                />
            break;

        case 'dropzone':
            const parentOnChange = onChange;
            result = 
                <DropzoneArea
                    {...props}
                    onChange={
                        (files) => {
                            if (! files) return;                            

                            if (files.length === 0) return;
                        
                            let file = files[0];
                            let reader = new FileReader();
                            reader.onload = (e) => {
                                let content = btoa(reader.result);
                                parentOnChange(props.name, content);
                                parentOnChange(`${props.name}_file_data`, file);
                                props.notifyOnChange && props.notifyOnChange(props.name, file, reader.result);
                            }
                            reader.readAsBinaryString(file);
                        }
                    }

                    onDelete={ () => {
                        parentOnChange(props.name, ''); 
                        parentOnChange(`${props.name}_file_data`, null);
                    }}
                />
            break;
        
        case 'mac-scanner':
            result = <MacScanner
                        key={`field_${props.name}`}
                        value={ value }
                        onChange={onChange}
                        {...props}
                    />
            break;

        case 'marker-preview': 
            result = <MarkerPreview 
                backgroundUrl={props.background}
                markerUrl={value}
            />
            break;

        case 'label':
            result = <div {...props}>{props.label}</div>
            break;

        case 'address_map': 
            result = <AddressMap onChange={onChange} {...props}/>
            break;

        //case 'email':
        //case 'password':
        //case 'text':
        //case 'date':
        //case 'datetime':
        default:
            result = 
                <Input
                    key={`field_${props.name}`}
                    value={ value }
                    onChange={onChange}
                    {...props}
                />
            break;
    }

    return result;
}

const Group = (props) => {    
    const {fields, values, onChange} = props;
    return (
        <div>
            {(props.title)?<h4>{props.title}</h4>:null}
            {fields.map( (field) => {return (<RenderField onChange={onChange} {...field} values={values} />);}  )}
        </div>
    );
        
}

export default Group;