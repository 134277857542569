import React, { Component } from 'react';
import { connect } from "react-redux";

import MaterialTable from 'material-table';
import { ITEMS_PER_PAGE } from '../constants/ui';
import { getUpdateInterval } from '../redux/reducers/ui';

class Table extends Component {
    constructor (props) {
      super(props);

      this.columns = props.columns;

      this.state = {
        search: props.searchValue || '',
        orderDirection: props.orderDirection || 'asc',
        orderByColumnIndex: props.orderByColumnIndex || null,
        page: props.page || 0,
        itemsPerPage: ITEMS_PER_PAGE, 
        timer: null,
        tableRef: (props.tableRef)?props.tableRef:React.createRef(),
      }

      if (! isNaN(this.state.orderByColumnIndex) && this.columns[this.state.orderByColumnIndex] )
        this.columns[this.state.orderByColumnIndex].defaultSort = this.state.orderDirection;

    }

    setTimer = () => {
      if (! this.props.autoRefresh) return;
      const timer = setInterval ( () => {
        this.state.tableRef.current && this.state.tableRef.current.onQueryChange();
      },this.props.updateInterval)
  
      this.setState ( {...this.state, timer: timer});
    }

    clearTimer = () => {
      if (! this.props.autoRefresh) return;
      if (this.state.timer !== null) clearInterval(this.state.timer);
    }

    componentDidMount = () => {
      this.setTimer();
    }

    componentWillUnmount = () => {
      this.clearTimer();
    }

    componentDidUpdate = (prevProps,  prevState, snapshot) => {
      if (prevProps.updateInterval !== this.props.updateInterval) {
        this.clearTimer();
        this.setTimer();
      }
    }

    handleChangeOrder = (index, direction) => {

      if (! isNaN(this.state.orderByColumnIndex) && this.columns[this.state.orderByColumnIndex] )
        delete(this.columns[this.state.orderByColumnIndex].defaultSort);

      if (! isNaN(index) && this.columns[index] )
        this.columns[index].defaultSort = direction;

      this.setState( {...this.state, orderByColumnIndex: index, orderDirection: direction});
    }

    handleChangeSearch = (search)  => {
      this.setState( { ...this.state, search: search});
    }

    render() {
      let { hidden, tableRef, ...props } = this.props;
      tableRef = this.state.tableRef;
      props.columns = this.columns;
      const style = hidden?{display: 'none'}:{};

      if (typeof props.defaultSortColumnIndex !== 'undefined') {
        props.columns[props.defaultSortColumnIndex].defaultSort = props.defaultSortDirection || 'asc';
      }
      const displaySearch = (typeof this.props.displaySearch === 'undefined')?true:(!!this.props.displaySearch);

      const tableOptions = this.props.tableOptions || {};

      return(
        <div style={style}>
        
        <MaterialTable
          {...this.props}
          tableRef={tableRef}
          title={props.title||''}
          options={{
            sorting:true,
            actionsColumnIndex: -1,
            //loadingType: 'linear',
            searchText: this.state.search,
            initialPage: this.state.page,
            pageSize: this.state.itemsPerPage,
            search: displaySearch,
            ...tableOptions
          }}
          localization={{
            body: {
              emptyDataSourceMessage: 'No hay registros'
            },
            toolbar: {
              searchTooltip: 'Buscar',
              searchPlaceholder: 'Buscar',
            },
            pagination: {
              labelRowsSelect: 'filas por página',
              labelDisplayedRows: '{count} de {from}-{to}',
              firstTooltip: 'Primer página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página'
            }
          }}

          components={{
            OverlayLoading: props => (<div></div>)
          }}

          onOrderChange={ (orderedColumnId, orderDirection) => { this.handleChangeOrder(orderedColumnId, orderDirection)}}
          onSearchChange={ (search) => { this.handleChangeSearch(search)}}

          //onChangePage={ (p) => {this.setState({...this.state, page: p})} }
          //onChangeRowsPerPage={ (p) => {this.setState({...this.state, itemsPerPage: p})}}
        />


      </div>
      )
    }
  }


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});
  
export default connect(mapStateToProps, mapDispatchToProps)(Table);