import { createSelector } from 'reselect';
import { handleActions } from 'redux-actions';

import { ACTION_SET_USER_LOGON, ACTION_LOGOUT_USER, ACTION_UPDATE_POSITION } from '../actions/index';

const defaultUserReducer = {
    is_logged_on: false, 
    token: null, 
    screen_name: null, 
    permission: [],
    position: null,
};

export const userReducer = handleActions({
    [ACTION_SET_USER_LOGON]: (state, action) => {
        localStorage.setItem('token', action.payload.token);
        return { ...state, ...action.payload, is_logged_on: true };
    },

    [ACTION_LOGOUT_USER]: state => {
        localStorage.removeItem("token");
        return { ...state,  is_logged_on: false, token: null, screen_name: null, permission: [],};
    },

    [ACTION_UPDATE_POSITION]: (state, action) => {
        return {...state, position: action.payload};
    }


}, defaultUserReducer);

/*
Si no uso redux-actions, así defino el reducer:
export const userReducer = (state = {}, action) => {
    let result = null;

    switch (action.type) {
        case ACTION_SET_USER_LOGON:
            result = {is_logged_on: true, 
                token: action.payload.token,
                screen_name: action.payload.screen_name,
                permission: action.payload.permission,
            };
            localStorage.setItem('token', action.payload.token);
            break;
    
        case ACTION_LOGOUT_USER:
            result = {is_logged_on: false, token: null, screen_name: null, permission: []};
            localStorage.removeItem("token");
            break;

        default:
            result = state;
            break;
    }

    return result;
}
*/
//Selectores
export const getUserToken = createSelector(
    (state) => state?.user?.token || null,
    token => token);

export const getUserScreenName = createSelector(
    (state) => state?.user?.screen_name || null,
    screen_name => screen_name
)

export const isUserLoggedOn = createSelector(
    (state) => state?.user?.is_logged_on || false,
    is_logged_on => is_logged_on
)

export const getUserPermission = createSelector(
    (state) => state?.user?.permission || [],
    permission => permission
)

export const getPosition = createSelector(
    (state) => state?.user?.position || null,
    position => position
)
