import React from 'react'
import { TextField, FormGroup, Box } from '@material-ui/core';

const Input = ( {onChange, value, ...props} ) => {
    return (
        <Box pt={3}>
            <FormGroup row>
                <TextField
                    key={`field_inner_${props.name}`}
                    type={props.type}
                    name={props.name}
                    value={ value }
                    onChange={ (ev) => onChange(ev.target.name, ev.target.value) }
                    {...props}
                    fullWidth            
                    />
            </FormGroup>
        </Box>
    )
}
export default Input;