import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const IconPicker = (props) => {
    const categories = props.categories || [];
    const icons = props.icons || [];
    const url = props.baseUrl;

    const [category, setCategory] = useState(props?.category || '');

    return (
        <>
            <InputLabel id="categorySelector">Categoría</InputLabel>
            <Select
                key="categorySelect"
                labelId="categorySelector"
                label="Categoría"
                value={category}
                fullWidth
                onChange={ (e) => {setCategory(e.target.value)}}
            >
                {categories.map( e => <MenuItem value={e}>{e}</MenuItem>)}        
            </Select>
            <div>
                {category && icons[category].map( e => {
                    return(<img 
                        src={`${url}/${category}/${e}`} 
                        alt={e} 
                        onClick={() => props.onSelect (category, e, `${url}/${category}/${e}`) }
                        />
                    );
                })}
            </div>
        </>
    );
}

IconPicker.propTypes = {
    category: PropTypes.string,
    categories: PropTypes.array.isRequired,
    icons: PropTypes.array.isRequired,
    baseUrl: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
}

export default IconPicker;