import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import promiseMiddleware from 'redux-promise';

import authInterceptor from '../middlewares/authInterceptor';
import logger from '../middlewares/logger';
import crashReporter from '../middlewares/crashReporter';
import { FOCUS_REFRESH_TIMEOUT } from '../../constants/ui';

const initialState = {
  loading: true,
  ui: {
    focus: true,
    update_interval: FOCUS_REFRESH_TIMEOUT,
  },
  user: {
    is_logged_on: false,
    token: null,
    screen_name: null,
    permission: [],
  }
};

//Esto es para la herramienta de debug
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reducers, 
  initialState,
  composeEnhancers ( applyMiddleware(thunk, authInterceptor, promiseMiddleware, logger, crashReporter) )
);