import React from 'react'
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';

import AbstractAbmPage from './AbstractAbmPage.js';
import { API_COMMAND, API_COMMAND_ACTIVATE, API_COMMAND_CREATE, API_COMMAND_DELETE, API_COMMAND_EDIT, API_COMMAND_LIST } from '../constants/api.js';
import ActiveIcon from '../components/icondisplay/ActiveIcon.js';
import CommandForm from '../components/forms/CommandForm.js';

class CommandPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = CommandForm;
        this.strings = {
            createItem: 'Nuevo comando',
            editItem: 'Editar comando',
            itemCreationSuccess: 'Comando creado exitosamente',
            itemUpdateSuccess: 'Comando actualizado exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el comando seleccionado',
                save: 'Ocurrió un error al guardar el comando. Intente nuevamente.',
            }
        }
        
        this.urls = {
            list: API_COMMAND_LIST,
            item: API_COMMAND,
            toggle: API_COMMAND_ACTIVATE,
            delete: API_COMMAND_DELETE,
            create: API_COMMAND_CREATE,
            update: API_COMMAND_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'asc',
            columns: [ 
                {id: 'description', field: 'description', title: 'Descripción', sorting: true},
                {id: 'cmd', field: 'cmd', title: 'Comando', sorting: true},
                {id: 'device', field: 'device_type.description', title: 'Equipo', sorting: false},
                {id: 'active', field: 'active', title: 'Activo', render: (e) => <ActiveIcon active={!!e.active} activeLabel="Activo" inactiveLabel="Inactivo"/>}
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nuevo comando',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Desactivar comando':'Activar comando',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => {
                    return ({
                        icon: 'edit',
                        tooltip: 'Editar comando',
                        onClick: () => {this.handleEdit(element.id) },
                    })
                },
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar comando',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar el comando ${element.description}?`) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(CommandPage));


