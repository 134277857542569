import React from 'react'
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';
import dayjs from 'dayjs';

import AbstractAbmPage from './AbstractAbmPage.js';
import { API_USER, API_USER_ACTIVATE, API_USER_CREATE, API_USER_DELETE, API_USER_EDIT, API_USER_LIST } from '../constants/api.js';

import ActiveIcon from '../components/icondisplay/ActiveIcon.js';
import UserForm from '../components/forms/UserForm.js';

class UserPage extends AbstractAbmPage {

    constructor (props) {
        super(props);
        
        this.formComponent = UserForm;
        this.strings = {
            createItem: 'Nuevo usuario',
            editItem: 'Editar usuario',
            itemCreationSuccess: 'Usuario creado exitosamente',
            itemUpdateSuccess: 'Usuario actualizado exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el usuario seleccionado',
                save: 'Ocurrió un error al guardar el usuario. Intente nuevamente.',
                1: 'Debe completar todos los campos',
                2: 'El tipo de usuario no es válido o no tiene permisos para crear un usuario de ese tipo',
                3: 'La dirección de E-mail ya está en uso',
                4: 'No se encuentra el cliente seleccionado',
                5: 'La contraseña es demasiado corta',
            }
        }
        
        this.urls = {
            list: API_USER_LIST,
            item: API_USER,
            toggle: API_USER_ACTIVATE,
            delete: API_USER_DELETE,
            create: API_USER_CREATE,
            update: API_USER_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'asc',
            columns: [ 
                {id: 'screen_name', field: 'screen_name', title: 'Nombre', sorting: true},
                {id: 'email', field: 'email', title: 'E-mail', sorting: true},
                {id: 'type', field: 'user_type.description', title: 'Tipo', sorting: true},
                {id: 'customer', field: 'customer.description', title: 'Cliente', sorting: true},
                {id: 'created_at', field: 'created_at', title: 'Creado', sorting: true, render: (e) => e.created_at && dayjs(e.created_at).format('DD/MM/YYYY HH:mm')},
                {id: 'last_login_at', field: 'last_login_at', title: 'Último login', sorting: true, render: (e) => e.last_login_at && dayjs(e.last_login_at).format('DD/MM/YYYY HH:mm')},
                {id: 'active', field: 'active', title: 'Activo', render: (e) => <ActiveIcon active={e.active} activeLabel="Usuario activo" inactiveLabel="Usuario inactivo"/>}
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nuevo usuario',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Desactivar usuario':'Activar usuario',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => {
                    return ({
                        icon: 'edit',
                        tooltip: 'Editar usuario',
                        onClick: () => {this.handleEdit(element.id) },
                    })
                },
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar usuario',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar al usuario ${element.scren_name}?`) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(UserPage));