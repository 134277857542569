import React from 'react'
import dayjs from 'dayjs';
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';
import { Icon, Chip } from '@material-ui/core';
import AbstractAbmPage from './AbstractAbmPage.js';
import { API_FIRMWARE, API_FIRMWARE_ACTIVATE, API_FIRMWARE_CREATE, API_FIRMWARE_DELETE, API_FIRMWARE_EDIT, API_FIRMWARE_LIST } from '../constants/api.js';
import ActiveIcon from '../components/icondisplay/ActiveIcon.js';
import FirmwareForm from '../components/forms/FirmwareForm.js';

class FirmwarePage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = FirmwareForm;
        this.strings = {
            createItem: 'Subir firmware',
            editItem: 'Editar firmware',
            itemCreationSuccess: 'Firmware cargado exitosamente',
            itemUpdateSuccess: 'Firmware actualizado exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el firmware seleccionado',
                save: 'Ocurrió un error al guardar el firmware. Intente nuevamente.',
                E01: 'Ya existe un firmware con este nombre de archivo',
                E02: 'Debe seleccionar al menos un tipo de dispositivo',
                E03: 'No se encuentra el formulario de configuración seleccionado',
                E04: 'El CRC32 del archivo es incorrecto',
                E05: 'Ocurrió un error al guardar el archivo de firmware en el servidor',
            }
        }
        
        this.urls = {
            list: API_FIRMWARE_LIST,
            item: API_FIRMWARE,
            toggle: API_FIRMWARE_ACTIVATE,
            delete: API_FIRMWARE_DELETE,
            create: API_FIRMWARE_CREATE,
            update: API_FIRMWARE_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'download_file', field: 'download_file', title: 'Archivo', sorting: false, render: (e) => {
                    return (<a href={e.uri}><Icon title="Descargar archivo">get_app</Icon></a>);
                }},
                {id: 'description', field: 'description', title: 'Descripción', sorting: true},
                {id: 'created_at', field: 'created_at', title: 'Creado', sorting: true, render: (e) => e.created_at && dayjs(e.created_at).format('DD/MM/YYYY HH:mm:ss')},
                {id: 'length', field: 'size', title: 'Bytes', sorting: false},
                {id: 'crc32', field: 'crc32', title: 'CRC32', sorting: false},
                {id: 'type', field: 'type', title: 'Tipo', sorting: true},
                {id: 'device_types', field: 'device_types', title: 'Equipos', sorting: false, render: (e) => {
                    return e.device_types.map( e => <Chip label={e.description} variant="outlined" />);
                }},
                {id: 'setup_template', field: 'setup_template.description', title: 'Configuración', sorting: true},
                {id: 'version', field: 'version', title: 'Versión', sorting: true},
                {id: 'compiled_at', field: 'compiled_at', title: 'Compilación', sorting: true, render: (e) => e.compiled_at && dayjs(e.compiled_at).format('DD/MM/YYYY HH:mm')},
                {id: 'updates', field: 'updates', title: 'Actualizaciones', sorting: false, render: (element) => {
                    const up = element.upgraded_devices;
                    const total = element.total_devices;
                    return (<span title={`${up} dispositivos actualizados de un total de ${total}`} >{`${up} / ${total}`}</span>)
                } },
                {id: 'active', field: 'active', title: 'Activo', render: (e) => <ActiveIcon active={!!e.active} activeLabel="Activo" inactiveLabel="Inactivo"/>}
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Cargar firmware',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Desactivar':'Activar',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar el firmware ${element.description}? Se cancelarán todos los OTA pendientes`) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(FirmwarePage));


