import React, { Component } from 'react'
import { PropTypes } from 'prop-types';
import '../styles/backgroundImage.scss';

class BackgroundImage extends Component {

    componentDidMount () {
      var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight|| e.clientHeight|| g.clientHeight;
      
      this.setState({x:x,y:y});
    }

    render (){
      const {zIndex = -9999999999999} = this.props;
      return (<div><img className='bg' src={this.props.src} alt="background" style={{zIndex: zIndex}}/></div>)
    }  
}

BackgroundImage.propTypes = {
    src: PropTypes.string.isRequired,
    zIndex: PropTypes.number,
}

export default BackgroundImage;