import React from 'react'
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';

import AbstractAbmPage from './AbstractAbmPage.js';
import { API_DEVICE_TYPE, API_DEVICE_TYPE_ACTIVATE, API_DEVICE_TYPE_CREATE, API_DEVICE_TYPE_DELETE, API_DEVICE_TYPE_EDIT, API_DEVICE_TYPE_LIST } from '../constants/api.js';
import DeviceTypeForm from '../components/forms/DeviceTypeForm.js';
import ActiveIcon from '../components/icondisplay/ActiveIcon.js';
import DeviceTypeFeaturesIcons from '../components/icondisplay/DeviceTypeFeaturesIcons';

class DeviceTypePage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = DeviceTypeForm;
        this.strings = {
            createItem: 'Nuevo tipo de equipo',
            editItem: 'Editar tipo de equipo',
            itemCreationSuccess: 'Tipo de equipo creado exitosamente',
            itemUpdateSuccess: 'Tipo de equipo actualizado exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el tipo de equipo seleccionado',
                save: 'Ocurrió un error al guardar el tipo de equipo. Intente nuevamente.',
            }
        }
        
        this.urls = {
            list: API_DEVICE_TYPE_LIST,
            item: API_DEVICE_TYPE,
            toggle: API_DEVICE_TYPE_ACTIVATE,
            delete: API_DEVICE_TYPE_DELETE,
            create: API_DEVICE_TYPE_CREATE,
            update: API_DEVICE_TYPE_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'asc',
            columns: [ 
                {id: 'name', field: 'name', title: 'Nombre', sorting: true},
                {id: 'description', field: 'description', title: 'Descripción', sorting: false},                
                {id: 'features', field: 'features', title: 'Características', sorting: false, render: (e) => <DeviceTypeFeaturesIcons hasEth={e.has_eth} simSupport={e.sim_support} hasGps={e.has_gps}/>},
                {id: 'existing_devices', field: 'existing_devices', title: 'Equipos', sorting: false},
                {id: 'active', field: 'active', title: 'Activo', render: (e) => <ActiveIcon active={!!e.active} activeLabel="Cliente activo" inactiveLabel="Cliente inactivo"/>}
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nuevo tipo de equipo',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Desactivar tipo de equipo':'Activar tipo de equipo',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => {
                    return ({
                        icon: 'edit',
                        tooltip: 'Editar tipo de equipo',
                        onClick: () => {this.handleEdit(element.id) },
                    })
                },
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar tipo de equipo',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar al tipo de equipo ${element.name}? ATENCIÓN: Esta acción eliminará también los equipos de este tipo que haya creado. Esta acción no se puede revertir.`) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(DeviceTypePage));


