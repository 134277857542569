import SimpleForm from './SimpleForm';
import PropTypes from 'prop-types';


const onSubmit = (form, props) => {
    if (props.isNew) {
        props.onCreate (form);

    } else {
        props.onUpdate (form, form.id);
    }
}

const DeviceTypeForm = (props) => {
    let myprops = {...props};
    
    myprops.schema = (values) => {
        let schema = {fields: [
            {
                key: 'id',
                name: 'id',
                type: 'hidden',
            },
            {
                key: 'name',
                name: 'name',
                type: 'text',
                label: 'Nombre',
                required: true,
                disabled: false,
                autoFocus: true,
            },
            {
                key: 'description',
                name: 'description',
                type: 'textarea',
                label: 'Descripción (Opcional)',
                required: false,
                disabled: false,
                autoFocus: false,
            },
            {
                key: 'sim_support',
                name: 'sim_support',
                type: 'switch',
                label: 'Soporta GSM',
            },
            {
                key: 'has_eth',
                name: 'has_eth',
                type: 'switch',
                label: 'Tiene puerto Ethernet',
            },
            {
                key: 'has_gps',
                name: 'has_gps',
                type: 'switch',
                label: 'Tiene GPS',
            },
            {
                key: 'active',
                name: 'active',
                type: 'switch',
                label: 'Activo',
            }
        ]};
        return schema;
    };
    myprops.onSubmit = (values) => onSubmit(values, myprops);
    myprops.onCancel = myprops.onClose;

    return (
        SimpleForm(myprops)
    );
}


DeviceTypeForm.propTypes={    
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    disableSaveButton: PropTypes.bool,
    isNew: PropTypes.bool.isRequired,
    values: PropTypes.object,
}

export default DeviceTypeForm;