//Icons
import InputIcon from '@material-ui/icons/Input';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SettingsIcon from '@material-ui/icons/Settings';
import DevicesIcon from '@material-ui/icons/Devices';
import EventIcon from '@material-ui/icons/Event';
import DirectionsIcon from '@material-ui/icons/Directions';
import SimCardIcon from '@material-ui/icons/SimCard';
import MemoryIcon from '@material-ui/icons/Memory';
import SaveIcon from '@material-ui/icons/Save';

//perfiles
import { USER_IS_ADMIN, USER_IS_ROOT, USER_IS_CUSTOMER_ADMIN, USER_IS_CUSTOMER } from "../../constants/profiles";

//Rutas
import { LOGOUT_URL, 
    PASSWORD_URL, 
    USER_URL, 
    CUSTOMER_URL, 
    SETUP_URL,
    DEVICE_TYPE_URL,
    EVENT_TYPE_URL,
    COMMAND_URL,
    SIMCARD_URL,
    FIRMWARE_URL,
    OTA_URL,
    DEVICE_URL,
    DEVICE_COMMAND_URL,
} from '../../constants/url' ;
import { Router } from '@material-ui/icons';

let menuCache = [];
let menuProfile = null;

export const menuGenerator = ( profile ) => {

    if ( (menuProfile === profile) && menuCache.length > 0) return menuCache;
    
    //Estos son todos los items de menú
    const allItems = [

      /*
      {
        type: 'title',
        title: 'Remotas',
        profiles: [],
      },
      {
        title: 'Mapa',
        href: MAP_URL,
        icon: <Map />,
        profiles: [],
      },
      {
        title: 'Comandos',
        href: COMMAND_URL,
        icon: <SettingsIcon />,
        profiles: [],
      },
      {
        title: 'Triggers',
        href: TRIGGERS_URL,
        icon: <AssignmentTurnedInIcon />,
        profiles: [],
      },
      {
        title: 'Eventos',
        href: EVENTS_URL,
        icon: <AlarmIcon />,
        profiles: [],
      },
      {
        type: 'separator',
        profiles: [],
      },
      {
        type: 'title',
        title: 'Ensamblajes',
        profiles: [],
      },
      {
        title: 'Ensamblajes',
        href: ASSEMBLY_URL,
        icon: <MoveToInbox />,
        profiles: [],
      },
      */
      {
        title: 'Comandos',
        href: DEVICE_COMMAND_URL,
        icon: <SettingsIcon />,
        profiles: [USER_IS_ROOT, USER_IS_ADMIN, USER_IS_CUSTOMER_ADMIN],
      },
      {
        title: 'Equipos',
        href: DEVICE_URL,
        icon: <Router />,
        profiles: [USER_IS_ROOT, USER_IS_ADMIN, USER_IS_CUSTOMER_ADMIN, USER_IS_CUSTOMER],
      },
      {
        title: 'Firmware',
        href: FIRMWARE_URL,
        icon: <MemoryIcon />,
        profiles: [USER_IS_ROOT, USER_IS_ADMIN],
      },
      {
        title: 'OTA',
        href: OTA_URL,
        icon: <SaveIcon />,
        profiles: [USER_IS_ROOT, USER_IS_ADMIN],
      },
      {
        title: 'Tarjetas SIM',
        href: SIMCARD_URL,
        icon: <SimCardIcon />,
        profiles: [USER_IS_ROOT],
      },
      {
        type: 'separator',
        profiles: [USER_IS_ROOT, USER_IS_ADMIN, USER_IS_CUSTOMER_ADMIN],
      }, 
      {
        type: 'title',
        title: 'Maestros',
        profiles: [USER_IS_ROOT, USER_IS_ADMIN, USER_IS_CUSTOMER_ADMIN],
      },
      /*
      {
        title: 'API Keys',
        href: API_KEY_URL,
        icon: <LockIcon />,
        profiles: [],
      },
      */
      {
        title: 'Clientes',
        href: CUSTOMER_URL,
        icon: <ShoppingBasketIcon />,
        profiles: [USER_IS_ROOT, USER_IS_ADMIN],
      },
      {
        title: 'Comandos',
        href: COMMAND_URL,
        icon: <DirectionsIcon />,
        profiles: [USER_IS_ROOT, USER_IS_ADMIN],
      },
      {
        title: 'Tipos de equipo',
        href: DEVICE_TYPE_URL,
        icon: <DevicesIcon />,
        profiles: [USER_IS_ROOT, USER_IS_ADMIN],
      },
      {
        title: 'Tipos de evento',
        href: EVENT_TYPE_URL,
        icon: <EventIcon />,
        profiles: [USER_IS_ROOT, USER_IS_ADMIN],
      },
      {
        title: 'Usuarios',
        href: USER_URL,
        icon: <PersonIcon />,
        profiles: [USER_IS_ROOT, USER_IS_ADMIN, USER_IS_CUSTOMER_ADMIN],
      },
      { 
        type: 'separator',
        profiles: [USER_IS_ROOT, USER_IS_ADMIN],
      },
      {
        type: 'title',
        title: 'Parámetros',
        profiles: [USER_IS_ROOT, USER_IS_ADMIN],
      },
      {
        title: 'Configuración',
        href: SETUP_URL,
        icon: <SettingsIcon />,
        profiles: [USER_IS_ROOT, USER_IS_ADMIN],
      },
      { 
        type: 'separator' 
      },
      {
        title: 'Cambiar contraseña',
        href:  PASSWORD_URL,
        icon: <VpnKeyIcon />
      },
      {
        title: 'Cerrar sesión',
        href: LOGOUT_URL,
        icon: <InputIcon />
      },
    ];


    /*
      Ahora necesito quedarme con todas las entradas de menú que:
        - No tengan indicación de perfil (O sea, que es para todos)
        - Que tengan indicación de perfil y contengan el perfil actual

      Además, quiero eliminar el campo de perfiles del array resultante.
    */
    
    const menu = 
        allItems
          .filter ( e => (! e.profiles ) || ( e.profiles.includes(profile)) )
          .map( ({profiles, ...rest}) => rest)
          .slice(0);

    menuCache = [ ...menu ];
    menuProfile = profile;

    return menu;
}