import { API_SERVER } from "./volatile";

export const API_AUTH = `${API_SERVER}/auth`;
export const API_AUTH_LOGIN = `${API_AUTH}/login`;
export const API_AUTH_LOGOUT = `${API_AUTH}/logout`;
export const API_AUTH_CHECK = `${API_AUTH}/check`;

export const API_PROFILE = `${API_SERVER}/profile`;
export const API_PROFILE_UPDATE_POSITION = `${API_PROFILE}/position`;
export const API_PROFILE_CHANGE_PASSWORD = `${API_PROFILE}/password`;

export const API_CUSTOMER_LIST = `${API_SERVER}/customers`;
export const API_CUSTOMER = `${API_SERVER}/customer/{$id}`;
export const API_CUSTOMER_CREATE = `${API_SERVER}/customer`;
export const API_CUSTOMER_EDIT = API_CUSTOMER;
export const API_CUSTOMER_ACTIVATE = API_CUSTOMER;
export const API_CUSTOMER_DELETE = API_CUSTOMER;

export const API_USER_LIST = `${API_SERVER}/users`;
export const API_USER = `${API_SERVER}/user/{$id}`;
export const API_USER_CREATE = `${API_SERVER}/user`;
export const API_USER_EDIT = API_USER;
export const API_USER_ACTIVATE = API_USER;
export const API_USER_DELETE = API_USER;
export const API_USER_FORM = `${API_SERVER}/forms/user`;

export const API_DEVICE_TYPE_LIST = `${API_SERVER}/device_types`;
export const API_DEVICE_TYPE = `${API_SERVER}/device_type/{$id}`;
export const API_DEVICE_TYPE_CREATE = `${API_SERVER}/device_type`;
export const API_DEVICE_TYPE_EDIT = API_DEVICE_TYPE;
export const API_DEVICE_TYPE_ACTIVATE = API_DEVICE_TYPE;
export const API_DEVICE_TYPE_DELETE = API_DEVICE_TYPE;

export const API_EVENT_TYPE_LIST = `${API_SERVER}/event_types`;
export const API_EVENT_TYPE = `${API_SERVER}/event_type/{$id}`;
export const API_EVENT_TYPE_CREATE = `${API_SERVER}/event_type`;
export const API_EVENT_TYPE_EDIT = API_EVENT_TYPE;
export const API_EVENT_TYPE_ACTIVATE = API_EVENT_TYPE;
export const API_EVENT_TYPE_DELETE = API_EVENT_TYPE;
export const API_EVENT_TYPE_FORM = `${API_SERVER}/forms/event_type`;

export const API_COMMAND_LIST = `${API_SERVER}/commands`;
export const API_COMMAND = `${API_SERVER}/command/{$id}`;
export const API_COMMAND_CREATE = `${API_SERVER}/command`;
export const API_COMMAND_EDIT = API_COMMAND;
export const API_COMMAND_ACTIVATE = API_COMMAND;
export const API_COMMAND_DELETE = API_COMMAND;
export const API_COMMAND_FORM = `${API_SERVER}/forms/command`;

export const API_SIMCARD_LIST = `${API_SERVER}/simcards`;
export const API_SIMCARD = `${API_SERVER}/simcard/{$id}`;
export const API_SIMCARD_CREATE = `${API_SERVER}/simcard`;
export const API_SIMCARD_EDIT = API_SIMCARD;
export const API_SIMCARD_ACTIVATE = API_SIMCARD;
export const API_SIMCARD_DELETE = API_SIMCARD;
export const API_SIMCARD_FORM = `${API_SERVER}/forms/simcard`;

export const API_FIRMWARE_LIST = `${API_SERVER}/firmwares`;
export const API_FIRMWARE = `${API_SERVER}/firmware/{$id}`;
export const API_FIRMWARE_CREATE = `${API_SERVER}/firmware`;
export const API_FIRMWARE_EDIT = API_FIRMWARE;
export const API_FIRMWARE_ACTIVATE = API_FIRMWARE;
export const API_FIRMWARE_DELETE = API_FIRMWARE;
export const API_FIRMWARE_FORM = `${API_SERVER}/forms/firmware`;

export const API_OTA_LIST = `${API_SERVER}/otas`;
export const API_OTA = `${API_SERVER}/ota/{$id}`;
export const API_OTA_CREATE = `${API_SERVER}/ota`;
export const API_OTA_EDIT = API_OTA;
export const API_OTA_ACTIVATE = API_OTA;
export const API_OTA_DELETE = API_OTA;
export const API_OTA_FORM = `${API_SERVER}/forms/ota`;

export const API_DEVICE_LIST = `${API_SERVER}/devices`;
export const API_DEVICE = `${API_SERVER}/device/{$id}`;
export const API_DEVICE_UPLOAD = `${API_SERVER}/device`;
export const API_DEVICE_ACTIVATE = API_DEVICE;
export const API_DEVICE_FORM = `${API_SERVER}/forms/devices`;
export const API_DEVICES_GRID_DATA = `${API_DEVICE_LIST}/data`;
export const API_DEVICE_DISPATCH_COMMAND = `${API_DEVICE}/dispatch/{$commandId}`;
export const API_DEVICE_UPDATE_FIRMWARE = `${API_DEVICE}/flash/{$firmwareId}`;
export const API_DEVICE_UPDATE_TYPE = `${API_DEVICE}/device_type/{$firmwareId}`;
export const API_DEVICE_SET_SIMCARD = `${API_DEVICE}/simcard/{$simcardId}`;
export const API_DEVICE_REMOVE_SIMCARD = `${API_DEVICE}/simcard`;
export const API_DEVICE_DELIVERY_FORM = `${API_SERVER}/forms/devices/delivery`;
export const API_DEVICE_DELIVERY_SET = `${API_DEVICE}/customer/{$customerId}`;
export const API_DEVICE_DELIVERY_REMOVE = `${API_DEVICE}/customer`;

export const API_DEVICE_COMMAND_LIST = `${API_SERVER}/device_commands`;
export const API_DEVICE_COMMAND = `${API_SERVER}/device_command/{$id}`;
export const API_DEVICE_COMMAND_ACTIVATE = `${API_SERVER}/device_command/{$id}`;
export const API_DEVICE_COMMAND_DELETE = `${API_SERVER}/device_command/{$id}`;

/*
export const API_PASSWORD_RECOVERY = `${API_SERVER}/password_recovery`;
export const API_PASSOWRD_RECOVERY_ISSUE = API_PASSWORD_RECOVERY;
export const API_PASSWORD_RECOVERY_CANCEL = API_PASSWORD_RECOVERY;
export const API_PASSWORD_RECOVERY_UPDATE = API_PASSWORD_RECOVERY;
export const API_PASSWORD_RECOVERY_CHECK = API_PASSWORD_RECOVERY;


export const API_INSTANCE_LIST = `${API_SERVER}/instances`;
export const API_INSTANCE = `${API_SERVER}/instance`;
export const API_INSTANCE_ACTIVATE = API_INSTANCE;
export const API_INSTANCE_DELETE = API_INSTANCE;
export const API_INSTANCE_CREATE = API_INSTANCE;

export const API_RTU_LIST = `${API_SERVER}/rtus`;
export const API_RTU = `${API_SERVER}/rtu/{$id}`;
export const API_RTU_COMMAND = `${API_RTU}/command/{$command_id}`;
export const API_RTU_IMPORT = `${API_SERVER}/rtu`;
export const API_RTU_FORM = `${API_SERVER}/forms/rtu`;
export const API_RTU_FAT = `${API_RTU}/fat`;
export const API_RTU_UPDATE_CONFIG = `${API_RTU}/config`;
export const API_RTU_PATCH = `${API_RTU}`;
export const API_RTU_DEBUG_LIST = `${API_RTU}/debug`;
export const API_RTU_DEBUG_DELETE = `${API_RTU}/debug/{$debugid}`;
export const API_RTU_HISTORY_LIST = `${API_RTU}/history`;

export const API_FIRMWARE_LIST = `${API_SERVER}/fws`;
export const API_FIRMWARE = `${API_SERVER}/fw`;
export const API_FIRMWARE_ACTIVATE = `${API_FIRMWARE}`;
export const API_FIRMWARE_DELETE = `${API_FIRMWARE}`;
export const API_FIRMWARE_CREATE = `${API_FIRMWARE}`;

export const API_OTA_LIST = `${API_SERVER}/otas`;
export const API_OTA = `${API_SERVER}/ota`;
export const API_OTA_ACTIVATE = `${API_OTA}`;
export const API_OTA_DELETE = `${API_OTA}`;
export const API_OTA_CREATE = `${API_OTA}`;
export const API_OTA_FORM = `${API_SERVER}/forms/ota`;

export const API_TRIGGER_LIST = `${API_SERVER}/triggers`
export const API_TRIGGER = `${API_SERVER}/trigger`
export const API_TRIGGER_ACTIVATE = `${API_TRIGGER}`;
export const API_TRIGGER_DELETE = `${API_TRIGGER}`;
export const API_TRIGGER_CREATE = `${API_TRIGGER}`;
export const API_TRIGGER_FORM = `${API_SERVER}/forms/trigger`;

export const API_EVENT_LIST = `${API_SERVER}/events`;
export const API_EVENT = `${API_SERVER}/event`;

export const API_COMMAND_RTU_LIST = `${API_SERVER}/commands`;
export const API_COMMAND_RTU = `${API_SERVER}/command/{$id}`;
export const API_COMMAND_RTU_ACTIVATE = `${API_COMMAND_RTU}`;
export const API_COMMAND_RTU_CANCEL = `${API_COMMAND_RTU}`;

export const API_ASSEMBLY_LIST = `${API_SERVER}/assemblies`;
export const API_ASSEMBLY = `${API_SERVER}/assembly/{$id}`;
export const API_ASSEMBLY_PHOTO = `${API_ASSEMBLY}/photo`;
export const API_ASSEMBLY_CREATE = `${API_SERVER}/assembly`;
export const API_ASSEMBLY_EDIT = `${API_ASSEMBLY}`;
export const API_ASSEMBLY_DELETE = `${API_ASSEMBLY}`;

export const API_DELIVERY_LIST = `${API_SERVER}/deliveries`;
export const API_DELIVERY = `${API_SERVER}/delivery/{$id}`;
export const API_DELIVERY_CREATE = `${API_SERVER}/delivery`;
export const API_DELIVERY_EDIT = `${API_DELIVERY}`;
export const API_DELIVERY_DELETE = `${API_DELIVERY}`;
export const API_DELIVERY_FORM = `${API_SERVER}/forms/delivery`;
export const API_DELIVERY_DOCUMENT = `${API_DELIVERY}/document`;

export const API_APIKEY_LIST = `${API_SERVER}/api_keys`;
export const API_APIKEY = `${API_SERVER}/api_key/{$id}`;
export const API_APIKEY_CREATE = `${API_SERVER}/api_key`;
export const API_APIKEY_DELETE = `${API_APIKEY}`;
export const API_APIKEY_FORM = `${API_SERVER}/forms/api_key`;

export const API_MAP_RTU_LIST = `${API_SERVER}/map/rtu`;

export const API_HISTORY_CREATE = `${API_SERVER}/history`;
export const API_HISTORY_INSTALLATION = `${API_SERVER}/history/{$id}/installation`;
export const API_HISTORY_TICKET = `${API_SERVER}/history/{$id}/ticket`;
export const API_HISTORY_TICKET_PRINT = `${API_SERVER}/history/{$id}/ticket/print`;
*/