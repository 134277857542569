import React, { Component } from 'react'
import ReactLoading from 'react-loading';
import { connect } from "react-redux";
import { BrowserRouter as Router, Redirect} from 'react-router-dom';
import Raven from 'react-raven';
import { ToastContainer, toast } from 'react-toastify';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';

import 'react-toastify/dist/ReactToastify.css';
import theme from './theme';
import './styles/index.scss';
//import 'react-perfect-scrollbar/dist/css/styles.css';

import { isUserLoggedOn, getUserPermission } from './redux/reducers/users';
import { isLoading } from './redux/reducers/loading';
import { setIsLoading } from './redux/actions';
import { setFocusState } from './redux/actions/ui';
import { logoutUser, updatePosition } from './redux/actions/users';
import { fetchProfile, doLogout as logoutFromServer } from './services/auth';
import { updatePosition as updatePositionOnServer} from './services/position';

import { LOGIN_URL } from './constants/url';

import Routes from './Routes';

const browserHistory = createBrowserHistory();

class App extends Component{
  constructor (props) {
    super(props);
    this.state = {
      watch_position: null,
    }
    this.props.setLoading(true);
    this.doLogout = this.doLogout.bind(this);
    //Si tengo en el store el token pero no estoy logueado, entonces tengo que obtener el perfil
  }

  doLogout() {
    logoutFromServer();
    this.props.logoutUser();
    toast.success('Se ha cerrado la sesión',{toastId: 'logout'});
    return (<Redirect to={LOGIN_URL}></Redirect>);
  }

  updatePosition (position) {
    const oPosition = {
      accuracy: position.coords.accuracy,
      altitude: position.coords.altitude,
      altitudeAccuracy: position.coords.altitudeAccuracy,
      heading: position.coords.heading,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      speed:  position.coords.speed,
      timestamp: position.timestamp,
    }
    this.props.updatePosition(oPosition);

    if (this.props.isUserLoggedOn) updatePositionOnServer({ ...oPosition });
  }

  onFocusChange  = (evt = null) => {
    const event = evt?.type || 'focus';
    const focus = (event === 'focus');
    console.log( 'Foco:', focus);
    this.props.setFocusState(focus);
    
  }

  componentDidMount() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition ( position => this.updatePosition(position) );
        const wp = navigator.geolocation.watchPosition ( position => this.updatePosition(position) );
        this.setState ( { ...this.state, watch_position: wp } );
    }
    window.addEventListener('focus', this.onFocusChange);
    window.addEventListener('blur', this.onFocusChange);
    fetchProfile();
  }

  componentWillUnmount() {
    if (this.state.watch_position !== null) navigator.geolocation.clearWatch(this.state.watch_position);
    window.removeEventListener('focus', this.onFocusChange);
    window.removeEventListener('blur', this.onFocusChange);
  }

  renderLoading () {
    return (
      <div
        style={{
          position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>       
    </div>);
  }

  render () {
    const isAuthenticated = this.props.isUserLoggedOn;
    const profiles = this.props.userProfiles;
    
    return (
      <Router>
        <div>
          <Raven dsn="https://4e75917b013a4f10ad3f380a1d96b89d@o392870.ingest.sentry.io/5241160" />
          {this.props.isLoading?
            this.renderLoading()
          :
            <ThemeProvider theme={theme}>
              <Router history={browserHistory}>
                <Routes 
                  isAuthenticated={isAuthenticated}
                  profiles={profiles}
                  logout={this.doLogout}
                />
              </Router>
            </ThemeProvider>
  
          }
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>        
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  isUserLoggedOn: isUserLoggedOn(state),
  isLoading: isLoading(state),
  userProfiles: getUserPermission(state),
});

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logoutUser()),
  updatePosition: value => dispatch(updatePosition(value)),
  setLoading: value => dispatch(setIsLoading(value)),
  setFocusState: value => dispatch(setFocusState(value)),
});


/*
App.defaultProps = {
  is_logged_on: false, token: null, screen_name: null, permission: []
}
*/

export default connect(mapStateToProps, mapDispatchToProps)(App);
/*
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/