import React, { Component } from 'react'
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import { API_DEVICE_FORM } from '../../constants/api';
import loggedUserServerFetch from '../../services/loggedUserServerFetch';
import AbstractForm from './AbstractForm'
import { Grid } from '@material-ui/core';

class DeviceForm extends Component {
    constructor (props) {
        super(props);

        props.values.device_type_id = props?.values?.device_type?.id;
        
        this.state = {
            deviceTypes: [],
        };
    }

    componentDidMount = async () => {
        try {
            const response = await loggedUserServerFetch (API_DEVICE_FORM, 'get', {}, {}, {});
            await new Promise ( (resolve) => this.setState({...this.state, deviceTypes: response?.data?.device_type, resolve}));

        } catch (error) {
            console.error (error);
            toast.error ('Ocurrió un error al inicializar el formulario de comandos');
            this.props.onClose();            
        }
    }

    buildSchema = (values) => {
        let deviceTypesOptions = {};
        this.state.deviceTypes && this.state.deviceTypes.forEach ( d => deviceTypesOptions[d.id] = `${d.name} - ${d.description}`);
        
        let schema = {fields: [
            {
                key: 'macs',
                name: 'mac',
                type: 'textarea',
                label: 'Direcciones MAC (Una por línea)',
                required: true,
                disabled: false,
                autoFocus: true,
            },
            {
                key: 'deviceTypeId',
                name: 'device_type_id',
                label: 'Tipo de dispositivo',
                required: true,
                disabled: false,
                autoFocus: false,
                type: 'select',
                options: deviceTypesOptions,
            },
            {
                key: 'active',
                name: 'active',
                type: 'switch',
                label: 'Activo',
            }
        ]};
        return schema;
    }

    preProcessForm = (form) => {
        form.macs = (form?.mac || '').toLowerCase().replace(/-/g,'').replace(/:/g,'').replace(/_/g,'').split("\n");
        return form;
    }

    validateForm = (form) => {
        const macRx = new RegExp(/^[a-f0-9]{12}$/);
        const allMacs = form?.macs?.length|| -1;

        if (allMacs < 1) throw Error('Debe ingresar al menos una MAC');

        const validMacs = (form?.macs || []).filter ( mac => mac.match(macRx)).length;

        if (allMacs !== validMacs) throw Error('Hay direcciones MAC con formato incorrecto');

        return true;
    }

    renderLoading = () => {
        return (
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item alignContent="center">
                    <ReactLoading className="loading" type="bars" color="#17a2b8" width="64px" height="64px"/>
                </Grid>
            </Grid>);
    }

    render = () => {
        const schema = this.buildSchema;
        if (this.state.customers === null && this.state.user_types === null) return this.renderLoading();
        return <AbstractForm schema={schema} preProcessForm={this.preProcessForm} validateForm={this.validateForm} {...this.props} />
    }
}

export default DeviceForm;