import axios from 'axios';

import { API_AUTH_LOGIN, API_AUTH_LOGOUT, API_AUTH_CHECK } from "../constants/api"
import { getPosition } from '../redux/reducers/users.js';
import { setUserLogon, setIsLoading } from "../redux/actions";
import { store } from '../redux/store';
import { logoutUser } from '../redux/actions/users';

export const doLogin = (user, pass) => {
    const state = store.getState();
    const position = getPosition(state);
    let payload = {
        'user': user,
        'pass': pass,
    }

    if (position?.latitude && position?.longitude) {
        payload['lat'] = position.latitude;
        payload['lng'] = position.longitude;
    }
    
    const url = API_AUTH_LOGIN;

    return axios ({
        url: url,
        method: 'post',
        data: payload,
    });
};

export const doLogout = () => {
    const token = localStorage.token;
    
    return axios ({
        url: API_AUTH_LOGOUT,
        method: 'post',
        headers: {
            'x-auth': token,
        }
    });
};

export const fetchProfile = () => {
    const token = localStorage.token;
    if (token) {
        store.dispatch(setIsLoading(true));
        return axios ({
            url: API_AUTH_CHECK,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'x-auth': `${token}`,
            }
        }).then ( (result) => {
            //console.log (result.data);
            const data = result.data;
            store.dispatch(setUserLogon(data));

        }).catch ( (err) => {
            localStorage.removeItem('token');
            store.dispatch(setIsLoading(false));

        }).then( (result) => {
            store.dispatch(setIsLoading(false));
        });

    } else {
        store.dispatch(logoutUser());
        store.dispatch(setIsLoading(false));
    }
};