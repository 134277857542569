import SimpleForm from './SimpleForm';
import PropTypes from 'prop-types';


const onSubmit = (form, props) => {
    if (props.isNew) {
        props.onCreate (form);

    } else {
        props.onUpdate (form, form.id);
    }
}

const CustomerForm = (props) => {
    let myprops = {...props};
    
    myprops.schema = (values) => {
        let schema = {fields: [
            {
                key: 'id',
                name: 'id',
                type: 'hidden',
            },
            {
                key: 'description',
                name: 'description',
                type: 'text',
                label: 'Nombre del cliente',
                required: true,
                disabled: false,
                autoFocus: true,
            },
            {
                key: 'active',
                name: 'active',
                type: 'switch',
                label: 'Activo',
            }
        ]};
        return schema;
    };
    myprops.onSubmit = (values) => onSubmit(values, myprops);
    myprops.onCancel = myprops.onClose;

    return (
        SimpleForm(myprops)
    );
}


CustomerForm.propTypes={    
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    disableSaveButton: PropTypes.bool,
    isNew: PropTypes.bool.isRequired,
    values: PropTypes.object,
}

export default CustomerForm;