import React from 'react'
import { withStyles } from '@material-ui/styles';
import ReactDomServer from 'react-dom/server';

import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';

import AbstractAbmPage from './AbstractAbmPage.js';
import DeviceForm from '../components/forms/DeviceForm.js';
import { API_DEVICE, API_DEVICE_ACTIVATE, API_DEVICE_DISPATCH_COMMAND, API_DEVICE_LIST, API_DEVICE_UPDATE_FIRMWARE, API_DEVICE_UPLOAD, API_DEVICE_UPDATE_TYPE, API_DEVICE_SET_SIMCARD, API_DEVICE_REMOVE_SIMCARD, API_DEVICE_DELIVERY_REMOVE, API_DEVICE_DELIVERY_SET } from '../constants/api';
import DeviceStatusIcons from '../components/device/status.js';
import DeviceTableDropdownMenu from '../components/DeviceTableDropdownMenu';
import loggedUserServerFetch from '../services/loggedUserServerFetch';
import { API_DEVICES_GRID_DATA } from '../constants/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { getUserPermission } from '../redux/reducers/users.js';
import { USER_IS_ADMIN, USER_IS_ROOT } from '../constants/profiles.js';
import { FEATURE_ETH, FEATURE_GPS, FEATURE_SIM } from '../constants/device_features.js';
import DeviceTypeFeaturesIcons from '../components/icondisplay/DeviceTypeFeaturesIcons.js';
import DeviceDeliverForm from '../components/forms/DeviceDeliverForm.js';
import PopUp from '../components/popup/Popup.js';

const createSuccessMessage = (response) => {
    return (
        <>
            <span>Se dieron de alta nuevos equipos</span>            
            <div><strong>Insertados</strong>: {response?.added?.length || 0}</div>
            <div><strong>Existentes</strong>: {response?.exists?.length || 0}</div>
            <div><strong>Error</strong>: {response?.error?.length || 0}</div>
        </>
    );
}


class DevicePage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = DeviceForm;
        this.strings = {
            createItem: 'Alta de dispositivos',
            editItem: '',
            itemCreationSuccess: createSuccessMessage,
            itemUpdateSuccess: '',
            deliverySuccess: 'Equipo entregado correctamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el cliente seleccionado',
                save: 'Ocurrió un error al guardar el equipo. Intente nuevamente.',
                save_delivery: 'Ocurrió un error al gurdar la entrega. Intente nuevamente',
                E01: 'No se especificaron direcciones MAC válidas',
                E02: 'No se pudo encontrar el tipo de dispositivo seleccionado',
                E03: 'Hay direcciones MAC en formato inválido',
                E04: 'No se pudo dar de alta ningún equipo. Las direcciones ya existen o hubo otro problema',
                E05: 'El equipo tiene un comando encolado. Espere a que finalice la ejecución o cancélelo',
                E06: 'No existe el comando seleccionado',
                E07: 'No existe el firmware seleccionado',
                E08: 'No existe el tipo de equipo seleccionado',
                E09: 'No se encontró la tarjeta SIM seleccionada',
                E10: 'El equipo no tiene tarjeta SIM instalada',
                E11: 'El equipo ya tiene una tarjeta SIM instalada',
                E12: 'El equipo no está asignado a ningún cliente',
                E13: 'No se encuentra el cliente seleccionado',
                E14: 'El equipo ya está asignado a un cliente',
                E15: 'El equipo no tiene GPS, debe especificar la ubicación de destino del equipo',
            }
        }
        
        this.urls = {
            list: API_DEVICE_LIST,
            item: API_DEVICE,
            toggle: API_DEVICE_ACTIVATE,
            delete: null,
            create: API_DEVICE_UPLOAD,
            update: null,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'mac', field: 'mac', title: 'Mac', sorting: true},
                {id: 'friendly_name', field: 'friendly_name', title: 'Nombre', sorting: true},
                {id: 'customer_name', field: 'customer.description', title: 'Cliente', sorting: true},
                {id: 'device_type_name', field: 'device_type.name', title: 'Tipo', sorting: true},
                {id: 'firmware', field: 'firmware.version', title: 'Firmware', sorting: true},
                {id: 'status', field: 'usr_status', title: 'Estado', sorting: false, render: e => (<DeviceStatusIcons device={e}/>)},
            ],
            actions:[
                (element) => ({
                    icon: 'more_vert',
                    tooltip: `Más opciones de ${element?.mac}`,
                    onClick: (event) => {  this.setState( {...this.state, actionsAnchorEl: event.currentTarget, element }) },
                }),
            ]
        };

        //Solo el usuario root y admin puede crear equipos
        if ([USER_IS_ROOT, USER_IS_ADMIN].includes(this.getPermission()))
            this.tableSetup.actions.push (
                {
                    icon: 'cloud_upload',
                    tooltip: 'Cargar equipos',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
            );

        this.state.element = null;
        this.state.actionsAnchorEl = null;
        this.state.commands = [];
        this.state.firmwares = [];
        this.state.device_types = [];
        this.state.simcards = [];
        this.state.mobile_operators = [];
        this.state.display_delivery_form = false;
        this.state.device = null;

        this.handleDeliverToCustomer = this.handleDeliverToCustomer.bind(this);
    }

    getPermission = () => {        
        return Array.isArray(this.props.userPermission)? [...this.props.userPermission].shift() : this.props.userPermission;
    }

    loadAuxiliarData = async () => {
        try {
            const url = `${API_DEVICES_GRID_DATA}`;
            const response = await loggedUserServerFetch(url, 'get');
            this.setState ( {...this.state, 
                commands: response.data.commands || [],
                firmwares: response.data.firmwares || [],
                device_types: response.data.device_types || [],
                simcards: response.data.simcards || [],
                mobile_operators: response.data.mobile_operators || [],
            });
            
        } catch (error) {
            toast.error('No se pudo obtener la lista de comandos desde el servidor', 'cmd_retrieve_error');
            console.error(error);
        }
    }

    componentDidMount = async () => {
        await this.loadAuxiliarData();
    }

    handleCloseMenu = async () => {
        await this.setStateAsync({...this.state, actionsAnchorEl: null, element: null});
    }

    handleDispatchCommand = async (device, command) => {
        try {
            const url = API_DEVICE_DISPATCH_COMMAND.replace('{$id}', device.id).replace('{$commandId}', command.id);
            await loggedUserServerFetch(url, 'put');
            toast.success ('Comando encolado exitosamente', 'handle_dispatch_command_success');
            this.updateTable();
            
        } catch (error) {
            const defaultMsg  = `Ocurrió un error al despachar el comando ${command.description} (${command.cmd}) al equipo ${device.mac}`;
            let msg = this.pickErrorMessage(error?.response?.data?.status || null, defaultMsg, error);
            console.error(error, error.response.data);
            toast.error(msg, 'handle_dispatch_command_error');
        }
    }

    handleDispatchFirmwareUpdate = async (device, firmware) => {
        try {
            let response = await Swal.fire({
                html: `¿Desea actualizar el firmware del dispositivo ${device.mac}? <br />Versión actual: <strong>${device?.firmware?.version || 'desconocida'}</strong><br /> Nueva versión: <strong>${firmware.version} (${firmware.description})</strong>.`,
                showCancelButton: true,
                confirmButtonText: 'Actualizar',
                cancelButtonText:'Cancelar',
                icon: 'question',    
            })

            if (!response.value) return;

            const url = API_DEVICE_UPDATE_FIRMWARE.replace('{$id}', device.id).replace('{$firmwareId}', firmware.id);

            await loggedUserServerFetch (url, 'put');
            this.updateTable();            
            toast.success ('Actualización de firmware encolada exitosamente', 'handle_update_firmware');


        } catch (error) {
            const defaultMsg  = `Ocurrió un error al despachar la actualización de firmware ${firmware.version} (${firmware.description}) al equipo ${device.mac}`;
            let msg = this.pickErrorMessage(error?.response?.data?.status || null, defaultMsg);
            if (typeof msg === 'function') msg = msg(error);            
            toast.error(msg, 'handle_dispatch_command_error');
            console.error(error, error?.response);
        }
    }

    handleSetDeviceType = async (device) => {
        try {
            let inputOptions = {};
            let helperOptions = {};
            this.state.device_types.forEach ( (e) => {
                inputOptions[e.id] = `${e.name} - ${e.description}`;
                helperOptions[e.id] = e;
            })

            let result = await Swal.fire({
                html: 'Seleccione un tipo de dispositivo <div></div> ',
                input: 'select',
                icon: 'question',
                inputPlaceholder: 'Tipo de dispositivo...',
                inputOptions: inputOptions,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Seleccionar',
                didOpen: () => {
                    const input = Swal.getInput();
                    input.oninput = () => {
                        const value = parseInt(input.value);
                        const dt = helperOptions[value] || null;
                        const placeholder = Swal.getHtmlContainer().querySelector('div');
                        placeholder.textContent = '';
                        
                        if (dt !== null && dt.features) {
                            const has_eth = dt.features.includes(FEATURE_ETH);
                            const sim_support = dt.features.includes(FEATURE_SIM);
                            const has_gps = dt.features.includes(FEATURE_GPS);
                            placeholder.innerHTML = ReactDomServer.renderToString(<DeviceTypeFeaturesIcons hasEth={has_eth} simSupport={sim_support} hasGps={has_gps}/>);
                        }
                
                    }
                }
            })

            if (!result.isConfirmed || ! result.value) return;

            const url = API_DEVICE_UPDATE_TYPE.replace('{$id}', device.id).replace('{$firmwareId}', result.value);
            await loggedUserServerFetch(url, 'patch');
            toast.success ('Tipo de dispositivo asociado exitosamente', 'devicetype_set_success');
            this.updateTable();
            
        } catch (error) {
            const defaultMsg  = `Ocurrió un error al cambiar el tipo de dispositivo de ${device.mac}`;
            let msg = this.pickErrorMessage(error?.response?.data?.status || null, defaultMsg);
            if (typeof msg === 'function') msg = msg(error);            
            toast.error(msg, 'handle_dispatch_command_error');
            console.error(error, error?.response);
        }
    }

    handleSetSim = async (element) => {
        try {
            let inputOptions = {};
            let helperOptions = {};

            this.state.mobile_operators.forEach ( e => inputOptions[e.description] = {});
            
            this.state.simcards.forEach ( e => {
                inputOptions[e.provider.description][e.id] = `Teléfono: ${e.phone} - IMSI: ${e.imsi}`;
                helperOptions[e.id] = e;
            })
            
            Object.keys(inputOptions).forEach (key => { if (Object.keys(inputOptions[key]).length === 0 ) delete inputOptions[key] });

            let result = await Swal.fire({
                html: 'Seleccione una tarjeta SIM',
                input: 'select',
                icon: 'question',
                inputPlaceholder: 'Tarjeta SIM...',
                inputOptions: inputOptions,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Seleccionar',
            });

            if (! result.isConfirmed ||  !result.value) return;

            const url = API_DEVICE_SET_SIMCARD.replace('{$id}', element.id).replace('{$simcardId}', result.value);

            await loggedUserServerFetch(url, 'put');

            this.loadAuxiliarData();
            this.updateTable();

            toast.success ('Simcard asociada exitosamente', 'simcard_set_success');
            
        } catch (error) {
            const defaultMsg  = `Ocurrió un error al asociar la simcard al equipo ${element.mac}`;
            let msg = this.pickErrorMessage(error?.response?.data?.status || null, defaultMsg);
            if (typeof msg === 'function') msg = msg(error);            
            toast.error(msg, 'handle_dispatch_command_error');
            console.error(error, error?.response);
        }
    }

    handleRemoveSim = async (element) => {
        try {
            let response = await Swal.fire({
                html: `¿Desea remover la tarjeta SIM del equipo ${element.mac}? <br /> IMSI: ${element.simcard.imsi}, numero: ${element.simcard.phone}`,
                showCancelButton: true,
                confirmButtonText: 'Remover',
                cancelButtonText:'Cancelar',
                icon: 'question',    
            })

            if (!response.value) return;
            const url = API_DEVICE_REMOVE_SIMCARD.replace('{$id}', element.id);

            await loggedUserServerFetch(url, 'delete');

            this.loadAuxiliarData();
            this.updateTable();

            toast.success ('Simcard removida exitosamente', 'simcard_remove_success');
            
        } catch (error) {
            const defaultMsg  = `Ocurrió un error al retirar la simcard del equipo ${element.mac}`;
            let msg = this.pickErrorMessage(error?.response?.data?.status || null, defaultMsg);
            if (typeof msg === 'function') msg = msg(error);            
            toast.error(msg, 'handle_dispatch_command_error');
            console.error(error, error?.response);
            
        }
    }

    handleDeliverToCustomer = async (device) => {
        try {
            await new Promise ( resolve => setTimeout ( resolve, 500));
            const newState = { ...this.state, device: device, display_delivery_form: true };
            await this.setStateAsync ( newState );

        } catch (error) {
            console.error(error);            
        }
    }

    handleReceiveFromCustomer = async (device) => {
        try {
            let response = await Swal.fire({
                html: `¿Desea recibir el equipo ${device.mac} del cliente ${device?.customer?.description}?`,
                showCancelButton: true,
                confirmButtonText: 'Recibir',
                cancelButtonText:'Cancelar',
                icon: 'question',    
            })

            if (!response.value) return;
            const url = API_DEVICE_DELIVERY_REMOVE.replace('{$id}', device.id);

            await loggedUserServerFetch(url, 'delete');

            this.updateTable();

            toast.success ('Equipo recibido exitosamente', 'device_received_success');
            
        } catch (error) {
            const defaultMsg  = `Ocurrió un error al recibir el equipo ${device.mac}`;
            let msg = this.pickErrorMessage(error?.response?.data?.status || null, defaultMsg);
            if (typeof msg === 'function') msg = msg(error);
            toast.error(msg, 'handle_dispatch_command_error');
            console.error(error, error?.response);            
        }
    }    

    handleCloseDeliveryForm = () => {
        this.setState ( {...this.state, device: null, display_delivery_form: false}, this.updateTable );
    }

    handleSaveDelivery = async (form) => {
        let result = null;
        try {
            const url = API_DEVICE_DELIVERY_SET.replace('{$id}', this.state.device.id).replace('{$customerId}', form.customerId);
            result = await loggedUserServerFetch(url, 'put', form);
            result = result?.data;
            this.handleCloseDeliveryForm();
            toast.success(this.buildMessage(this.strings.deliverySuccess, result), 'success_deliver_item');

        } catch (error) {
            let msg = this.pickErrorMessage(error?.response?.data?.status || null, this.strings.error.save_delivery, error);
            console.error(error, error?.response);
            toast.error(msg, 'handle_deliver_error');
        }

        return result;
    }

    postRender = () => {
        const { classes } = this.props;
        return (
            <>
                <PopUp
                    title={`Entregar equipo ${this?.state?.device?.mac}`}
                    open={this.state.display_delivery_form}
                    classes={classes}
                    onClose = { () => {} }
                    maxWidth={this.popupMaxWidth}
                    fullWidth
                >
                    <DeviceDeliverForm 
                        device = { this.state.device }
                        open = { true }
                        onClose = { this.handleCloseDeliveryForm }
                        onCreate = { this.handleSaveDelivery }
                    />
                </PopUp>
                <DeviceTableDropdownMenu 
                    anchor = { this.state.actionsAnchorEl }
                    open = { !! this.state.actionsAnchorEl }
                    element = { this.state.element }
                    onClose = { this.handleCloseMenu }
                    key = "dropdownActionMenu"
                    
                    commands = { this.state.commands }
                    firmwares = { this.state.firmwares }
                    simcards = { this.state.simcards }

                    handleDispatchCommand = { this.handleDispatchCommand }
                    handleDispatchFirmwareUpdate = { this.handleDispatchFirmwareUpdate }
                    handleSetDeviceType = { this.handleSetDeviceType }
                    handleSetSim = { this.handleSetSim }
                    handleRemoveSim = { this.handleRemoveSim }

                    handleDeliverToCustomer = { this.handleDeliverToCustomer }
                    handleReceiveFromCustomer = { this.handleReceiveFromCustomer }

                />
            </>
        )
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
    userPermission: getUserPermission(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(DevicePage));