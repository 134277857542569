import React, { useState } from 'react'
import { withStyles, Paper, Grid, TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';

import { toast } from 'react-toastify';

import { MIN_PASSWORD_LENGTH } from '../../constants/ui';

const styles = theme => ({
    margin: {
        margin: theme.spacing.unit * 2,
    },
    padding: {
        padding: theme.spacing.unit
    }
});

const validatePassword = (oldPassword, newPassword1, newPassword2, closeSessions, doCangePassword) => {
    if (newPassword1 !== newPassword2) {
        toast.error ('La nueva contraseña no coincide con la verificación');
        return false;
    }
    
    if (newPassword1.length < MIN_PASSWORD_LENGTH) {
        toast.error('La nueva contraseña es demasiado corta');
        return false;
    }

    doCangePassword(oldPassword, newPassword1, closeSessions);
}


const ChangePasswordForm = (props) => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword1, setNewPassword1] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const [closeSessions, setCloseSessions] = useState(true);
    const { loading, classes, doCangePassword } = props;

    return (
        <Paper className={classes.padding} style={ {minWidth: '100%'} }>
            <form method="post" onSubmit= { (evt) => {
                evt.preventDefault();
                validatePassword (oldPassword, newPassword1, newPassword2, closeSessions, doCangePassword);
            }} >
                <div className={classes.margin}>
                    <Grid container spacing={8} alignItems="flex-end" style={{ paddingTop: 30 }}>
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField 
                                id="oldpassword" 
                                label="Contraseña actual" 
                                type="password" 
                                autoComplete="current-password"
                                fullWidth 
                                autoFocus 
                                required 
                                name="oldpass"
                                value={oldPassword}
                                onChange={ (ev) => { setOldPassword(ev.target.value) } }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField 
                                id="newpassword1" 
                                label={`Nueva contraseña (Al menos ${MIN_PASSWORD_LENGTH} caracteres)`}
                                type="password" 
                                autoComplete="new-password"
                                fullWidth 
                                required 
                                name="newPass1"
                                value={newPassword1}
                                onChange={ (ev) => { setNewPassword1(ev.target.value) } }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item md={true} sm={true} xs={true}>
                            <TextField 
                                id="newpassword2" 
                                label='Repetir la nueva contraseña'
                                type="password" 
                                autoComplete="new-password"
                                fullWidth 
                                required 
                                name="newPass2"
                                value={newPassword2}
                                onChange={ (ev) => { setNewPassword2(ev.target.value) } }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={8} alignItems="flex-end">
                        <Grid item md={true} sm={true} xs={true}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={closeSessions}
                                        onChange={ (ev) => { setCloseSessions(ev.target.checked); } }
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Cerrar todas las sesiones excepto esta"
                            />
                        </Grid>
                    </Grid>

                    <Grid container justify="center" style={{ paddingTop: 30 }}>
                        <Button 
                            variant="contained" 
                            color="primary"
                            type="submit" 
                            disabled={loading}
                            style={{ textTransform: "none" }}>Cambiar contraseña</Button>
                    </Grid>
                </div>
            </form>
            </Paper>

    );
}

export default withStyles(styles)(ChangePasswordForm);