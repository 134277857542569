import React from 'react';
import { Icon } from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import PropTypes from 'prop-types';

const CommandDeviceStatus = (props) => {
    const element = props.command_device;
    let txt = null;
    let deco = null;
    let icon = null;

    if (element.cancelled) {
        txt = 'Cancelado';
        deco = { color: red[500] };
        icon = 'cancel';
    
    } else {
        if (element.executed) {
            txt = 'Comando ejecutado';
            deco = { color: green[500] };
            icon = 'check_circle_outline';
    
        } else if (element.uploaded) {
            txt = 'Comando descargado, pendiente de ejecución';
            deco = { color: green[500] };
            icon = 'cloud_download';
        
        } else {
            txt = element.active?'Comando pendiente de descarga':'Pausado';
            deco = element.active?{ color: green[500] }:{ color: yellow[500] };
            icon = element.active?'hourglass_empty':'pause_circle_outline';
    
        }
    }
    return (<Icon style={deco} title={txt}>{icon}</Icon>)
}

CommandDeviceStatus.propTypes = {
    command_device: PropTypes.object.isRequired,
}

export default CommandDeviceStatus;
