import React from 'react'
import { withStyles } from '@material-ui/styles';
import { pageStyle } from "../styles/styles.js";
import { connect } from "react-redux";
import { getUpdateInterval } from '../redux/reducers/ui';

import AbstractAbmPage from './AbstractAbmPage.js';
import { API_CUSTOMER, API_CUSTOMER_ACTIVATE, API_CUSTOMER_CREATE, API_CUSTOMER_DELETE, API_CUSTOMER_EDIT, API_CUSTOMER_LIST } from '../constants/api.js';
import CustomerForm from '../components/forms/CustomerForm.js';
import ActiveIcon from '../components/icondisplay/ActiveIcon.js';

class CustomerPage extends AbstractAbmPage {

    constructor (props) {
        super(props);

        this.formComponent = CustomerForm;
        this.strings = {
            createItem: 'Nuevo cliente',
            editItem: 'Editar cliente',
            itemCreationSuccess: 'Cliente creado exitosamente',
            itemUpdateSuccess: 'Cliente actualizado exitosamente',
            error:{
                server_comm: 'Ocurrió un error en la comunicación con el servidor',
                not_found: 'No se encuentra el cliente seleccionado',
                save: 'Ocurrió un error al guardar el cliente. Intente nuevamente.',
            }
        }
        
        this.urls = {
            list: API_CUSTOMER_LIST,
            item: API_CUSTOMER,
            toggle: API_CUSTOMER_ACTIVATE,
            delete: API_CUSTOMER_DELETE,
            create: API_CUSTOMER_CREATE,
            update: API_CUSTOMER_EDIT,
        }
        
        this.tableSetup = {
            defaultSortColumnIndex: 0,
            defaultSortDirection: 'desc',
            columns: [ 
                {id: 'description', field: 'description', title: 'Cliente', sorting: true},
                {id: 'devices', field: 'devices', title: 'Equipos asignados', sorting: false},
                {id: 'users', field: 'users', title: 'Usuarios', sorting: false},
                {id: 'active', field: 'active', title: 'Activo', render: (e) => <ActiveIcon active={!!e.active} activeLabel="Cliente activo" inactiveLabel="Cliente inactivo"/>}
            ],
            actions:[
                {
                    icon: 'add',
                    tooltip: 'Nuevo cliente',
                    isFreeAction: true,
                    onClick: () => { this.handleCreate({active: true}) },
                },
                (element) => ({
                    icon: element.active?'pause':'play_arrow',
                    tooltip: element.active?'Desactivar cliente':'Activar cliente',
                    onClick: () => {  this.handleToggleActive(element.id, ! element.active) },
                }),
                (element) => {
                    return ({
                        icon: 'edit',
                        tooltip: 'Editar cliente',
                        onClick: () => {this.handleEdit(element.id) },
                    })
                },
                (element) => ({
                    icon: 'delete',
                    tooltip: 'Eliminar cliente',
                    onClick: () => { this.handleDelete(element.id, `¿Desea eliminar al cliente ${element.description}?`) }
                }),
            ]
        };    
    }
}


const mapStateToProps = state => ({
    updateInterval: getUpdateInterval(state),
});

const mapDispatchToProps = dispatch => ({});

export default withStyles(pageStyle)(connect(mapStateToProps, mapDispatchToProps)(CustomerPage));