import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { Grid } from '@material-ui/core';
const Caman = window.Caman;


class ColorEditor extends Component {
    constructor (props) {
        super(props);
        this.state = {
            color: props.color || '#000000',
            image: null,
            random: Math.floor(Math.random() * 99999),
        }
        this.imgRef = React.createRef();
    }

    convertHexToRGB = (hex) => {
		hex = hex.replace('#','');
		const r = parseInt(hex.substring(0,2), 16);
		const g = parseInt(hex.substring(2,4), 16);
		const b = parseInt(hex.substring(4,6), 16);

        return { r, g, b};
    }

    handleColorChange = (colorObject) => {
        this.setState ( {...this.state, color: colorObject.hex}, this.updatePreview(colorObject.rgb) );
    }

    updatePreview = (rgba) => {
        const rgb = rgba;

        const imgRef = this.imgRef.current.id;
        const self = this;
        
        Caman ( `#${imgRef}`, function () {            
            this.reset();
            this.channels({
                red: rgb.r * 100/255,
                green: rgb.g * 100/255,
                blue: rgb.b * 100/255});
            this.render( function () {                
                const encodedImg = this.toBase64();
                self.setState({...self.state, image: encodedImg}, () => {
                    self.props.onUpdate && self.props.onUpdate ( self.state.color, encodedImg);
                });
                const img = document.getElementById(imgRef);
                img.src = encodedImg;

            });
        })
    }

    render = () => {
        const {background} = this.props;
        const markerUrl = this.props.markerUrl || '/markers/no-marker.png';    
        const color = this.state.color;
    
        return (<>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <SketchPicker
                        disableAlpha={ true }
                        color={ color }
                        presetColors={ [] }
                        onChange={ this.handleColorChange }
                    />
                </Grid>
                <Grid item>
                    <div 
                        class="text-center" 
                        style={ {backgroundImage: `url('${background}')`, width: '320px', height: '120px', backgroundRepeat:'no-repeat', backgroundSize: 'cover'}}>
                            <img 
                                crossOrigin = "anonymous"
                                id={`Icon_Preview_${this.state.random}`}
                                style={{position: 'relative', top: '50px', left: '50%'}}
                                alt="Marker preview"
                                ref={ this.imgRef }
                                src={ markerUrl }
                            />
                    </div>                
                </Grid>
            </Grid>
        </>);
    }
}

ColorEditor.propTypes = {
    color: PropTypes.string,
    markerUrl: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    background: PropTypes.string.isRequired,
    onUpdate: PropTypes.func,
}

export default ColorEditor;