import SimpleForm from './SimpleForm';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-toastify';


const onSubmit = async (form, props, setWorking) => {
    setWorking(true);

    
    try {    
        if (props.preProcessForm) {
            form = await props.preProcessForm (form);
        }
    
        if (props.validateForm) {
            const result = await props.validateForm (form);
            if (result !== true ) throw result;
        }

        if (props.isNew) {
            await props.onCreate (form);
    
        } else {
            await props.onUpdate (form, form.id);
        }
        
    } catch (error) {        
        toast.error (error?.message || 'Ocurrió un error desconocido al enviar el formulario al servidor', 'onsubmit_error');
        console.error(error);
    }
    
    setWorking(false);
}

const AbstractForm = (props) => {
    let {disableSaveButton, ...myprops} = {...props};

    

    const [working, setWorking] = useState(false);
    
    /*
    myprops.schema = (values) => {
        let schema = {fields: [
            {
                key: 'id',
                name: 'id',
                type: 'hidden',
            },
            {
                key: 'description',
                name: 'description',
                type: 'text',
                label: 'Nombre del cliente',
                required: true,
                disabled: false,
                autoFocus: true,
            },
            {
                key: 'active',
                name: 'active',
                type: 'switch',
                label: 'Activo',
            }
        ]};
        return schema;
    };
    */
    myprops.onSubmit = async (values) => await onSubmit(values, myprops, setWorking);
    myprops.onCancel = myprops.onClose;
    myprops.disableSaveButton  = disableSaveButton || working;

    return (
        SimpleForm(myprops)
    );
}


AbstractForm.propTypes={    
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    disableSaveButton: PropTypes.bool,
    isNew: PropTypes.bool.isRequired,
    values: PropTypes.object,
    schema: PropTypes.any.isRequired,
}

export default AbstractForm;