import React, { Component } from 'react'
import Group from './group';
import Swal from 'sweetalert2';

import WithOnClick from './withOnClick';
import { Button, CardActions, Box } from '@material-ui/core';
import { ArrowBack, PostAdd, Save } from '@material-ui/icons';

const buildFieldsDict = (values, fields) => {
    let form = {};

    fields.forEach ( (field) => {
        if (field.type === 'group') {
            const groupFields = buildFieldsDict ( values, field.fields );
            form = {...form, groupFields };
        } else {
            form[field.name] = values[field.name] || null;
        }
    })
    return form;
}

class Form extends Component {
    constructor (props) {
        super (props);

        let schema = this.props?.schema || {};
        if (typeof schema === 'function') schema = schema(this.props?.values||{});

        this.state = {
            changed: false,
            form: buildFieldsDict(this.props?.values||{}, schema?.fields || []),
            //form: this.props.values||{},
        };    }

    handleBack = (ev) => {
        if (this.state.changed) {
            Swal.fire ({
                title: 'El formulario tiene cambios',
                text: '¿Desea salir sin guardar?',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Sí, salir',
                cancelButtonText: 'Quedarme aquí',

            }).then( (result) => {
                result.value && this.setState( {...this.state, changed: false});
                result.value && this.props.onCancel();
            });

        } else {
            this.props.onCancel();
        }

    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        this.setState( {...this.state, changed: false});
        this.props.onSubmit(this.state.form);
    }

    handleSaveDraft = () => {
        this.setState( {...this.state, changed: false});
        this.props.onSaveDraft(this.state.form);
    }

    handleChange = (name, value) => {
        let currentState = {...this.state};
        currentState['form'][name] = value;
        currentState.changed = true;
        this.setState(currentState);
    }

    renderBackButton = () => {
        let button = null;
        if (this.props.buttons?.back) {
            button = WithOnClick(this.handleBack)(this.props.buttons.back)

        } else {
            button = <Button 
                type="button"
                variant="contained" 
                color="default"
                title="Cerrar"
                onClick={this.handleBack}
                startIcon={<ArrowBack />}
            >Volver</Button>            
        }
        return button;
    }

    renderSubmitButton = () => {
        let button = null;
        if (this.props.buttons?.submit) {
            button = this.props.buttons.submit;

        } else {
            button = <Button
                type="submit"
                variant="contained"
                color="primary"
                title="Guardar"
                startIcon={<Save />}
                disabled={this.props.disableSaveButton||false}
            >Guardar</Button>
        }

        return button;
    }

    renderSaveDraftButton = () => {
        let button = null;
        if (typeof this.props.onSaveDraft === 'undefined') return null;

        if (this.props.buttons?.saveDraft) {
            button = WithOnClick(this.handleSaveDraft)(this.props.buttons.saveDraft)

        } else {
            button = <Button 
                type="button"
                variant="contained" 
                color="secondary"
                title="Guardar borrador"
                startIcon={<PostAdd />}
                onClick={this.handleSaveDraft}
            >Guardar borador</Button>
        }

        return button;
    }

    getSchema = () => {
        let {schema} = this.props;

        if (typeof schema === 'function') schema = schema(this.state.form);

        return schema;
    }

    render () {
        const {hidden, children} = this.props;
        
        if (hidden) return null;

        const schema = this.getSchema();
        const {title, fields} = schema;
        const butonAlign = this.props.buttons?.align || 'right';

        const BackButton = this.renderBackButton;
        const SaveDraftButton = this.renderSaveDraftButton;
        const SubmitButton = this.renderSubmitButton;

        return (
            <form method="post" onSubmit={this.handleSubmit}>                
                {title && <h3>{title}</h3>}
                <Group
                    key='main_group'
                    fields={fields}
                    values={this.state.form}
                    onChange={this.handleChange}
                />
                {children}
                <Box pt={3} pb={1}>
                    <CardActions style={{justifyContent: butonAlign}}>
                        <BackButton />
                        <SaveDraftButton />
                        <SubmitButton />
                    </CardActions>
                </Box>
            </form>
        );
    }
}

export default Form;