import  {} from '../actions';
import { logoutUser } from '../actions/index';

const authInterceptor = ({ dispatch }) => (next) => (action) => {
  if (action.status === 401) {
    console.log('*** authInterceptor detected NOT AUTHORIZED ***');
    dispatch(logoutUser());
  } else {
    next(action);
  }
};

export default authInterceptor;