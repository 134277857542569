import React, {Component} from 'react'

import { withStyles, Grid } from '@material-ui/core';

import { toast } from 'react-toastify';

import loggedUserServerFetch from '../services/loggedUserServerFetch';

import { API_PROFILE_CHANGE_PASSWORD } from '../constants/api';
import { PASSWORD_CHANGE_OK, PASSWORD_TOO_SHORT, PASSWORD_MISMATCH } from '../constants/ui';
import ChangePasswordForm from '../components/forms/ChangePasswordForm';

const styles = theme => ({
    root: {
      padding: theme.spacing(3)
    },
    content: {
      marginTop: theme.spacing(2)
    }
});


class PasswordPage extends Component {
    constructor (props) {
        super(props);
        this.state = { 
            submitting: false,
            key: Date.now(),
        }
        this.doChangePassword = this.doChangePassword.bind(this);
    }

    doChangePassword (oldPassword, newPassword, closeSessions) {
        this.setState( {...this.state, submitting: true});

        loggedUserServerFetch (API_PROFILE_CHANGE_PASSWORD, 'post', {
            old: oldPassword, 
            new: newPassword,
            close: closeSessions,        
        }).then ( ( response ) => {
            let r = 99;
            if (typeof response.data?.status !== 'undefined') r = response.data?.status;
        
    
            switch (r) {
                case PASSWORD_CHANGE_OK:
                    this.setState( {...this.state, key: Date.now()});
                    toast.success('La contraseña se cambió exitosamente');
                    break;
    
                case PASSWORD_TOO_SHORT:
                    toast.error('La nueva contraseña es demasiado corta');
                    break;
    
                case PASSWORD_MISMATCH:
                    toast.error('La contraseña actual es inválida');
                    break;
    
                default:
                    toast.error('Ocurrió un error al intentar cambiar la contaseña');
                    break;
            }        
            
        }).catch ( ( error ) => {
            if (!! error.alreadyHandled ) toast.error('Ocurrió un error al comunicarse con el servidor');
    
        }).then ( () => {
            this.setState( {...this.state, submitting: false});            
        })
    }

    render () {
        const { classes } = this.props;
        return(
            <div className={classes.root}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    >
                    <Grid item xs={12} md={12}>
                        <ChangePasswordForm
                            key={this.state.key}
                            loading={this.state.submitting}
                            doCangePassword={this.doChangePassword}
                        />
                    </Grid>   
                </Grid> 
    
            </div>
        );
    }
}

export default withStyles(styles)(PasswordPage);