import React from 'react';
import { Icon } from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import PropTypes from 'prop-types';

const OtaStatus = (props) => {
    const element = props.ota;
    let txt = null;
    let deco = null;
    let icon = null;

    if (element.cancelled) { 
        txt = 'Cancelado';
        deco = { color: red[500] };
        icon = 'cancel';

    } else if (element.end_download_at) {
        txt = 'Transferido';
        deco = { color: green[500] };
        icon = 'check_circle_outline';

    } else {
        txt = element.active?'Disponible':'Pausado';
        deco = element.active?{ color: green[500] }:{ color: yellow[500] };
        icon = element.active?'play_circle_outline':'pause_circle_outline';
    }
    return (<Icon style={deco} title={txt}>{icon}</Icon>)
}

OtaStatus.propTypes = {
    ota: PropTypes.object.isRequired,
}

export default OtaStatus;
