import React, { Component } from 'react'
import CropFreeIcon from '@material-ui/icons/CropFree';
import { IconButton, Grid, TextField } from '@material-ui/core';
import QrcodeDecoder from 'qrcode-decoder/dist/index.esm';
import { toast } from 'react-toastify';

class MacScanner extends Component {
    constructor (props) {
        super(props);        
        this.inputFileRef = React.createRef();
        this.state = {
            value: props.value||'',
        };
    }

    handleTextChange = (event) => {
        let value = event.target.value;        
        value = value.replace(/[^a-fA-F0-9 ]/g, "").slice(0,12).toLowerCase();        
        this.setState({...this.state, value:value}, () => { this.onChangeIfValidMac() });
    }

    isValidMac = (value) => {
        return /^[0-9a-f]{12}$/.test(value);
    }

    onChangeIfValidMac = () => {        
        if (this.isValidMac(this.state.value) ) {
            this.props.onChange(this.props.name, this.state.value);
            this.props.listenOnChange && this.props.listenOnChange(this.state.value);
        }
    }

    triggerLoadFile = () => {
        this.inputFileRef.click();
    }

    qrScan = (e) => {
        console.log('Scanning QR');
        const self = this;
        const node = e.target                
        const qr = new QrcodeDecoder();

		var reader = new FileReader();
		reader.onload = function() {            
			node.value = "";
            console.log (reader.result);
            qr.decodeFromImage(reader.result).then ( (result) => {                
                let res = result.data;
                if (! res) {                 
                    console.error('Unable to decode QR');
                    toast.error('No se encontró código QR o no se pudo decodificar. Asegúrese que el código QR esté dentro de la pantalla de la cámara y vuelva a intentar');
                } else  {
                    console.log('QR decoded', res);
                    res = res.replace(/:/g, '').replace(/-/g, '').replace(/_/g, '');
                    res = res.replace(/[^a-fA-F0-9 ]/g, "").slice(0,12).toLowerCase();                    
                    console.log('Sanitized', res);
                    if (self.isValidMac (res)) {
                        console.log('Valid mac found', res);
                        self.setState({...self.state, value:res}, () => { self.onChangeIfValidMac() });
                    } else {
                        toast.error('No se encontró una MAC válida');
                    }
                }
            });
		};        
		reader.readAsDataURL(node.files[0]);
    }

    render = () => {        
        const key = {...this.props};
        return(
            <Grid container direction="row" alignItems="flex-end">
                <Grid item xs={11}>
                    <TextField
                        {...this.props}
                        key={`${key}_field`}
                        inputProps = {{ 'aria-label': 'Ingresar MAC o escanear código QR' }}
                        label = "Ingresar MAC o escanear código QR"
                        onChange = { this.handleTextChange }
                        value = { this.state.value }
                        maxLength = { 12 }
                        fullWidth
                        required={this.props.required}
                    />
                </Grid>
                <Grid item xs>
                    <IconButton 
                        key={`${key}_button`}
                        type="button" 
                        aria-label="Escanear código QR"
                        title="Escanear código QR"
                        onClick={this.triggerLoadFile}
                    >
                        <CropFreeIcon />
                    </IconButton>
                    <input 
                        key={`${key}_aux`}
                        style={ { display: 'none'}}
                        type="file" 
                        accept="image/*" 
                        capture="environment" 
                        onChange={this.qrScan}
                        tabIndex={-1}
                        ref={input => this.inputFileRef = input}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default MacScanner;